import { AccessControlsResponse } from "@/types/accessControl";

const getRemoteControlPermission = (acl: AccessControlsResponse[] | null, functionName: string) => {
    let canStop = false;
    let canChangeOutput = false;
    let canCancelChange = false;

    if (!acl) {
        return [canStop, canChangeOutput, canCancelChange];
    }
    canStop = acl?.find((element) => element.FunctionId == functionName + ".remoteStop")?.IsPermitted || false;
    canChangeOutput = acl?.find((element) => element.FunctionId == functionName + ".remoteChangeOutput")?.IsPermitted || false;
    canCancelChange = acl?.find((element) => element.FunctionId == functionName + ".remoteCancelChange")?.IsPermitted || false;
    return [canStop, canChangeOutput, canCancelChange];
    
}

export default getRemoteControlPermission;