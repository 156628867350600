import { UserRole } from "@/types/user";

/**
 * Non-owner options.
 */
export const exceptOwnerRoleOptions = [
  {
    value: `${UserRole.MASTER_ADMIN},${UserRole.GROUP_ADMIN},${UserRole.GROUP_USER}`,
    label: "全て",
  },
  {
    value: UserRole.MASTER_ADMIN,
    label: "システム管理者",
  },
  {
    value: UserRole.GROUP_ADMIN,
    label: "会社管理者",
  },
  {
    value: UserRole.GROUP_USER,
    label: "ユーザー（社員）",
  },
];
