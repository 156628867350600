//<reference path="path/types.d.ts" />
import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { RemoteControlPostRequestPrameters, RemoteCotrolTokenPostRequestParameters } from '@/types/remoteControl';
  
@Module({ dynamic: true, store, name: 'remoteControls', namespaced: true })
class RemoteControls extends VuexModule {
    private errorMessage = {
        field: "" as any,
        msg: ""
    };
    @Action({ rawError: true })
    public async createRemoteControl(queryStringParameters?: RemoteControlPostRequestPrameters) {
        try {
            const result = await http.post('/output-power-control', queryStringParameters);
            this.SET_ERROR_MESSAGE({field: "", msg: ""});
            return result;
            
        } catch (error: any) {
            const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
            this.SET_ERROR_MESSAGE(errorMessage);   
        }

    }

    @Action({ rawError: true })
    public async getRemoteControlToken(queryStringParameters?: RemoteCotrolTokenPostRequestParameters) {
        try {
            const result = await http.post('/output-power-control/token', queryStringParameters);
            this.SET_ERROR_MESSAGE({field: "", msg: ""});
            return result;
        } catch (error: any) {
            const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
            this.SET_ERROR_MESSAGE(errorMessage);   
        }
    }


    @Action({})
    public async ErrorMessageHandling(error:string) {
    let message = {
        field: "",
        msg: "エラーが発生されました"
    }

    switch (error) {
        case "Password is incorrect":
            message  = {
                field: "password",
                msg: "パスワードが間違っています。"
            };
            break;
        // Add more error message here
        default:
            break;
    }
    return message;
    }
    @Mutation
    private SET_ERROR_MESSAGE(message: any): void {
      this.errorMessage = message;
    }
    get GET_ERROR_MESSAGE() {
        return this.errorMessage;
      }

}

export default getModule(RemoteControls);