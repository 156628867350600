import { IPagination } from "./../../types/pagination";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";
import { DateTime } from "luxon";

import sumOilConsumption from "@/store/helpers/sumOilConsumption";
import {
  AnalysisMonthItem,
  TimelineAnalysisData,
  TimelineData,
  TimelineRequestParameters,
  GeneratorData,
  TankData,
} from "@/types/timeline";

export const DIGITAL_SENSOR_CODES = [
  "sensor:fire-issue",
  "sensor:running",
  "sensor:voltage-establishment",
  "sensor:any-error",
  "sensor:low-battery",
  "sensor:stop-start",
];

@Module({ dynamic: true, store, name: "timeline", namespaced: true })
class Timeline extends VuexModule {
  // state
  private timelineData: TimelineData[] = [];
  private analysisData: TimelineAnalysisData[] = [];
  private analysisDataLookup: {
    [key: string]: TimelineAnalysisData[];
  } = {};
  private thisMonthOilConsumption: number | null = null;
  private analysisDataYear: AnalysisMonthItem[] = [];
  private timelineCurrentData: TimelineData[] = [];
  private generatorsData: GeneratorData[] = [];
  private tankData: TankData[] = [];
  private runningGenerators: GeneratorData[] = [];
  private lastMonthOilConsumption: number | null = null;

  private generatorsPagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({})
  public async fetchTimelineData(payload: {
    params: TimelineRequestParameters;
    options?: {
      page: boolean;
    };
    current: boolean;
  }): Promise<void> {
    // default options
    const options = payload.options
      ? payload.options
      : {
          page: false,
        };

    if (!options.page) {
      if (payload.current) {
        this.EMPTY_TIMELINE_CURRENT_DATA();
      } else {
        this.EMPTY_TIMELINE_DATA();
      }
    }
    try {
      const result = await http.get(`/timeline`, payload.params);
      // if (payload.current) {
      //   if (options.page) {
      //     this.PAGE_CURRENT_TIMELINE_DATA(result);
      //   } else {
      //     this.SET_TIMELINE_CURRENT_DATA(result);
      //   }
      // } else {
      if (options.page) {
        this.PAGE_CURRENT_TIMELINE_DATA(result);
        this.PAGE_TIMELINE_DATA(result);
      } else {
        this.SET_TIMELINE_DATA(result);
        this.SET_TIMELINE_CURRENT_DATA(result);
      }
      // }
      // Set generator data
      this.SET_GEN_DATA(result);
      // Set tank data
      this.SET_TANK_DATA(result);
      this.SET_RUNNING_GEN_DATA();
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchTimelineTanksData(payload: {
    params: TimelineRequestParameters;
    options?: {
      page: boolean;
    };
    current: boolean;
  }): Promise<void> {
    const options = payload.options
      ? payload.options
      : {
          page: false,
        };

    if (!options.page) {
      if (payload.current) {
        this.EMPTY_TIMELINE_CURRENT_DATA();
      } else {
        this.EMPTY_TIMELINE_DATA();
      }
    }
    try {
      const result = await http.get(`/timeline/tanks`, payload.params);

      if (payload.current) {
        if (options.page) {
          this.PAGE_CURRENT_TIMELINE_DATA(result);
        } else {
          this.SET_TIMELINE_CURRENT_DATA(result);
        }
      } else {
        if (options.page) {
          this.PAGE_TIMELINE_DATA(result);
        } else {
          this.SET_TIMELINE_DATA(result);
        }
      }

      // Set tank data
      this.SET_TANK_DATA(result);
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchTimelineAnalysisData(payload: {
    params: TimelineRequestParameters;
    options?: {
      page: boolean;
    };
    current: boolean;
  }): Promise<void> {
    // default options
    const options = payload.options
      ? payload.options
      : {
          page: false,
        };

    if (!options.page) {
      this.EMPTY_ANALYSIS_DATA();
    }
    try {
      const result = await http.get(`/timeline/analyses`, payload.params);
      if (options.page) {
        this.PAGE_ANALYSIS_DATA(result);
      } else {
        this.SET_ANALYSIS_DATA({
          analysisData: result,
          plantId: payload.params.plantId,
        });
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchThisMonthOilConsumption(plantId: string): Promise<void> {
    // default options

    this.EMPTY_OIL_CONSUMPTION();
    try {
      const startDate = DateTime.local().startOf("month");
      const params = {
        plantId: plantId,
        timestamp_from: startDate.toSeconds(),
      };
      const result = await http.get(`/timeline/oil-consumption`, params);
      //Endpoint now returns summed value
      this.SET_OIL_CONSUMPTION(result.OilConsumption); //may need to change this to match new name
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({})
  public async fetchLastMonthOilConsumption(plantId: string): Promise<void> {
    this.EMPTY_LAST_MONTH_OIL_CONSUMPTION();
    try {
      const startDate = DateTime.local().startOf("month").minus({ months: 1 });
      const endDate = DateTime.local().startOf("month").minus({ seconds: 1 });

      const params = {
        plantId: plantId,
        timestamp_from: startDate.toSeconds(),
        timestamp_to: endDate.toSeconds(),
      };
      const result = await http.get(`/timeline/oil-consumption`, params);

      //Endpoint now returns summed value
      this.SET_LAST_MONTH_OIL_CONSUMPTION(result.OilConsumption); //may need to change this to match new name
    } catch (error) {
      // tslint:disable-next-line:no-console
    }
  }

  @Action({ rawError: true })
  public async fetchTimelineAnalysisYearData(payload: {
    plantId: string;
    startDate: DateTime;
  }): Promise<void> {
    this.EMPTY_ANALYSIS_YEAR_DATA();
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const promises = months.map((month) => {
      const monthStart = payload.startDate.plus({ months: month - 1 });
      const monthEnd = monthStart.plus({ months: 1 });
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve): Promise<void> => {
        try {
          const params = {
            plantId: payload.plantId,
            timestamp_from: monthStart.toSeconds(),
            timestamp_to: monthEnd.toSeconds(),
          };
          const result = await http.get(`/timeline/analyses`, params);
          this.SET_ANALYSIS_YEAR_DATA({
            monthData: { month: monthStart.toLocaleString(), data: result },
            index: month - 1,
          });

          resolve();
        } catch (e) {
          this.SET_ANALYSIS_YEAR_DATA({
            monthData: { month: monthStart.toLocaleString(), data: [] },
            index: month - 1,
          });
          resolve();
        }
      });
    });
    await Promise.all(promises);
  }

  @Action
  public emptyAnalysisDataLookup() {
    this.EMPTY_ANALYSIS_DATA_LOOKUP();
  }

  // mutation
  @Mutation
  private SET_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineData = timelineData;
  }

  @Mutation
  private SET_ANALYSIS_DATA(payload: {
    analysisData: TimelineAnalysisData[];
    plantId: string;
  }) {
    this.analysisData = payload.analysisData;
    this.analysisDataLookup[payload.plantId] = payload.analysisData;
  }

  @Mutation
  private SET_OIL_CONSUMPTION(sum: number) {
    this.thisMonthOilConsumption = sum;
  }

  @Mutation
  private SET_LAST_MONTH_OIL_CONSUMPTION(sum: number) {
    this.lastMonthOilConsumption = sum;
  }

  @Mutation
  private SET_ANALYSIS_YEAR_DATA(payload: {
    monthData: AnalysisMonthItem;
    index: number;
  }) {
    this.analysisDataYear[payload.index] = payload.monthData;
  }

  // mutation
  @Mutation
  private SET_TIMELINE_CURRENT_DATA(timelineData: TimelineData[]) {
    this.timelineCurrentData = timelineData;
  }

  @Mutation
  private EMPTY_TIMELINE_DATA() {
    this.timelineData = [];
  }

  @Mutation
  private EMPTY_ANALYSIS_DATA_LOOKUP() {
    this.analysisDataLookup = {};
  }

  @Mutation
  private EMPTY_ANALYSIS_YEAR_DATA() {
    this.analysisDataYear = [];
  }

  @Mutation
  private EMPTY_ANALYSIS_DATA() {
    this.analysisData = [];
  }

  @Mutation
  private EMPTY_OIL_CONSUMPTION() {
    this.thisMonthOilConsumption = null;
  }

  @Mutation
  private EMPTY_LAST_MONTH_OIL_CONSUMPTION() {
    this.lastMonthOilConsumption = null;
  }

  @Mutation
  private EMPTY_TIMELINE_CURRENT_DATA() {
    this.timelineData = [];
  }

  // mutation
  @Mutation
  private PAGE_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineData = [...this.timelineData, ...timelineData];
  }

  // mutation
  @Mutation
  private PAGE_CURRENT_TIMELINE_DATA(timelineData: TimelineData[]) {
    this.timelineCurrentData = [...this.timelineCurrentData, ...timelineData];
  }

  @Mutation
  private PAGE_ANALYSIS_DATA(analysisData: TimelineAnalysisData[]) {
    this.analysisData = [...this.analysisData, ...analysisData];
  }

  /**
   * Obtain data on the generators of a certain plant
   */
  @Mutation
  private SET_GEN_DATA(timelineData: TimelineData[]) {
    this.generatorsData = timelineData[0].Generators; //FIXME: We need to create a function to get lastest time line data instead of using MAGIC NUMBER
    this.generatorsPagination.Count = timelineData[0].Generators.length;
  }

  @Mutation
  private SET_TANK_DATA(timelineData: TimelineData[]) {
    this.tankData = timelineData[0].Tanks; //FIXME: We need to create a function to get lastest time line data instead of using MAGIC NUMBER
  }

  /**
   * Obtain data on the generators of a certain plant (only those in operation)
   */
  @Mutation
  private SET_RUNNING_GEN_DATA() {
    const runningGenerators = this.generatorsData.filter((g: GeneratorData) => {
      return checkIsRunningGen(g);
    });
    this.runningGenerators = runningGenerators;

    /**
     * Determines whether a particular generator is in operation
     */
    const checkIsRunningGen = (generator: GeneratorData): boolean => {
      const isRunningGen = generator.Alerts.some((a) => {
        a.Name === "sensor:running";
      });
      return isRunningGen;
    };
  }

  // getter
  get GET_TIMELINE_DATA() {
    return this.timelineData;
  }

  get GET_ANALYSIS_DATA() {
    return this.analysisData;
  }

  get GET_ANALYSIS_DATA_LOOKUP() {
    return this.analysisDataLookup;
  }

  get GET_OIL_CONSUMPTION() {
    return this.thisMonthOilConsumption;
  }

  get GET_LAST_MONTH_OIL_CONSUMPTION() {
    return this.lastMonthOilConsumption;
  }

  get GET_ANALYSIS_YEAR_DATA() {
    return this.analysisDataYear;
  }

  get GET_TIMELINE_DATA_CURRENT() {
    return this.timelineCurrentData;
  }

  get GET_GEN_DATA() {
    return this.generatorsData;
  }

  get GET_TANK_DATA() {
    return this.tankData;
  }

  get GET_RUNNING_GEN_DATA() {
    return this.runningGenerators;
  }

  get GET_GENERATORS_PAGINATION(): IPagination {
    return this.generatorsPagination;
  }
}

export default getModule(Timeline);
