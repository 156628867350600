import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
  import store from '@/store';
  import { http } from '@/http/http';
import { Newcomer, NewcomerPairingRequest } from '@/types/newcomer';
  

  
  @Module({ dynamic: true, store, name: 'newcomers', namespaced: true })
  class Newcomers extends VuexModule {
    private newcomers: Newcomer[] = [];
    private errorMessage = '';
  
    @Action({ rawError: true })
    public async fetchNewcomers() {
      const result = await http.get('/devices/newcomer');
  
      this.SET_NEWCOMERS({ Data: result });
    }
  
    @Action({ rawError: true })
    public async requestPairing(payload: { data: NewcomerPairingRequest[] }) {
      const deviceList: string[] = [];
      payload.data.map((element: NewcomerPairingRequest) => {
          deviceList.push((element.Imsi) as string);
      })
      const promises = payload.data.map(async (newcomer: NewcomerPairingRequest) => {
        this.SET_ERROR_MESSAGE('');
        try {
          newcomer.DeviceList = deviceList;
          const deviceId = newcomer.Imsi;
          await http.put(`/devices/${deviceId}`, newcomer);
          delete newcomer.Imsi;
        } catch (error: any) {
          const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
          this.SET_ERROR_MESSAGE(errorMessage);
          throw new Error(errorMessage);
        }
      })
      await Promise.all(promises);
    }
  
    // mutation
    @Mutation
    private SET_NEWCOMERS(payload: { Data: Newcomer[] }) {
      this.newcomers = payload.Data;
    }

    @Mutation
    private SET_ERROR_MESSAGE(message: string): void {
      this.errorMessage = message;
    }

    @Action({})
    public async ErrorMessageHandling(error:string) {
      let message = "エラーが発生されました"
      if (error.includes("Devices-dev item not found for Id")) {
        message = "デバイスが見つからませんでした。別のIMSIで登録してください。"
      }
      // Add more error messages here
      else {
        message = "エラーが発生しました。"
      }
      return message;
    }
  
    // getter
    get GET_NEWCOMERS() {
      return this.newcomers;
    }

    get GET_ERROR_MESSAGE() {
      return this.errorMessage;
    }
  }
  
  export default getModule(Newcomers);
  