import { Device } from '@/types/device';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import { VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';

@Module({ dynamic: true, store, name: 'devices', namespaced: true  })
class Devices extends VuexModule {
  // state
  private devicesByIMSI: Partial<Device> = {};

  // action
  @Action({ rawError: true })
  public async fetchDevicesByIMSI(id: string) {
    const result = await http.get(`/devices/${id}`);
    this.SET_DEVICES_BY_IMSI(result);
  }

  // mutation
  @Mutation
  private SET_DEVICES_BY_IMSI(devices: Partial<Device>): void {
    this.devicesByIMSI = devices;
  }

  // getters
  get GET_DEVICES_BY_IMSI(): Partial<Device> {
    return this.devicesByIMSI;
  } 
}

export default getModule(Devices);

