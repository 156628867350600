import { Duration } from "@/types/shared/chart";
import { DateTime } from "luxon";

const getChartLabels = (duration: string): string[] => {
  let labels: any[] = [];

  switch (duration) {
    case Duration.HOUR: {
      let dateTime = DateTime.now().minus({ hours: 1 }).toSeconds();
      for (let i = 0; i <= 60; i++) {
        labels = [DateTime.fromSeconds(dateTime * 1000), ...labels];
        dateTime = dateTime + 60;
      }
      break;
    }

    case Duration.DAY: {
      const dateTime = DateTime.now().startOf("hour");
      for (let i = 0; i < 24; i++) {
        const eachHour = dateTime.minus({ hours: i });
        labels.push(eachHour);
      }
      break;
    }

    // case Duration.WEEK: {
    //   for (const i = dateTime.day(); labels.length <= 7; ) {
    //     labels = [
    //       dateTime.format('dd'),
    //       ...labels,
    //     ];
    //     dateTime.subtract(1, 'day');
    //   }

    //   break;
    // }

    case Duration.MONTH: {
      const dayCount = getDiffDays(1);
      const dateTime = DateTime.now().startOf("day");
      for (let i = 0; i < dayCount; i++) {
        const eachHour = dateTime.minus({ days: i });
        labels.push(eachHour);
      }
      break;
    }

    case Duration.THREE_MONTHS: {
      const dayCount = getDiffDays(3);
      const dateTime = DateTime.now().startOf("day");
      for (let i = 0; i < dayCount; i++) {
        const eachHour = dateTime.minus({ days: i });
        labels.push(eachHour);
      }
      break;
    }

    case Duration.YEAR: {
      const MONTHS_IN_YEAR = 12;
      const dateTime = DateTime.now().startOf("month");
      for (let i = 0; i < MONTHS_IN_YEAR; i++) {
        const eachMonth = dateTime.minus({ months: i });
        labels.push(eachMonth);
      }
      break;
    }
  }
  return labels;
};

/**
 * Get the number of days up to n months ago.
 * This process is used to get the appropriate number of days to display in the graph.
 */
const getDiffDays = (minusNumOfMonths: number) => {
  const SECONDS_IN_DAY = 60 * 60 * 24;
  const today = DateTime.now().startOf("day");
  const monthAgo = today.minus({ months: minusNumOfMonths });
  const differenceInDays =
    (today.toSeconds() - monthAgo.toSeconds()) / SECONDS_IN_DAY;
  return differenceInDays;
};

export default getChartLabels;
