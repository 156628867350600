import { CreateMaintenanceScheduleRequestBody } from "@/types/maintenanceSchedule";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";
import { IPagination } from "@/types/pagination";
import {
  MaintenanceSchedule,
  MaintenanceSchedulesRequestParameters,
} from "@/types/maintenanceSchedule";

@Module({
  dynamic: true,
  store,
  name: "maintenanceSchedules",
  namespaced: true,
})
class MaintenanceSchedules extends VuexModule {
  // state
  private maintenanceSchedule: Partial<MaintenanceSchedule> = {};
  // List of unperformed (reserved) maintenance.
  private pendingMaintenanceSchedules: MaintenanceSchedule[] = [];
  // List of maintenance work in progress.
  private inProgressMaintenanceSchedules: MaintenanceSchedule[] = [];
  // List of unperformed (reserved) maintenance by plantId.
  private pendingMaintenanceByPlant: MaintenanceSchedule[] = [];
  // List of maintenance work in progress by plantId.
  private inProgressMaintenanceByPlant: MaintenanceSchedule[] = [];

  // Get by companyId data or all data
  private pendingMaintenancePagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private inProgressMaintenancePagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  // Get by plantId data
  private pendingMaintByPlantPagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private inProgressMaintByPlantPagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({ rawError: true })
  public async fetchPendingMaintenanceSchedules(
    params: MaintenanceSchedulesRequestParameters
  ) {
    const result = await http.get("/maintenance-schedules", params);
    // If the query parameter has a plantId, the storage location of the fetched data will change.
    const hasPlantId = params?.plantId ? true : false;
    this.SET_PENDING_MAINTENANCE_SCHEDULES({
      MaintenanceSchedules: result.Data,
      Pagination: { Count: result.Count, Page: result.CurrentPage },
      hasPlantId: hasPlantId,
    });
  }

  @Action({ rawError: true })
  public async fetchIsProgressMaintenanceSchedules(
    params: MaintenanceSchedulesRequestParameters
  ) {
    const result = await http.get("/maintenance-schedules", params);
    // If the query parameter has a plantId, the storage location of the fetched data will change.
    const hasPlantId = params?.plantId ? true : false;
    this.SET_IN_PROGRESS_MAINTENANCE_SCHEDULES({
      MaintenanceSchedules: result.Data,
      Pagination: { Count: result.Count, Page: result.CurrentPage },
      hasPlantId: hasPlantId,
    });
  }

  @Action({ rawError: true })
  public async fetchMaintenanceSchedule(id: string) {
    const result = await http.get(`/maintenance-schedules/${id}`);
    this.SET_MAINTENANCE_SCHEDULE(result);
  }

  @Action({ rawError: true })
  public async createMaintenanceSchedule(payload: {
    body: Partial<CreateMaintenanceScheduleRequestBody>;
  }) {
    await http.post("/maintenance-schedules", payload.body);
  }

  @Action({ rawError: true })
  public async updateMaintenanceSchedule(payload: {
    id: string;
    body: CreateMaintenanceScheduleRequestBody;
  }) {
    await http.put(`/maintenance-schedules/${payload.id}`, payload.body);
  }

  // mutations
  @Mutation
  private SET_PENDING_MAINTENANCE_SCHEDULES(payload: {
    Pagination: IPagination;
    MaintenanceSchedules: MaintenanceSchedule[];
    hasPlantId: boolean;
  }): void {
    if (payload.hasPlantId) {
      this.pendingMaintenanceByPlant = payload.MaintenanceSchedules;
      this.pendingMaintByPlantPagination = payload.Pagination;
    } else {
      this.pendingMaintenanceSchedules = payload.MaintenanceSchedules;
      this.pendingMaintenancePagination = payload.Pagination;
    }
  }

  @Mutation
  private SET_IN_PROGRESS_MAINTENANCE_SCHEDULES(payload: {
    Pagination: IPagination;
    MaintenanceSchedules: MaintenanceSchedule[];
    hasPlantId: boolean;
  }): void {
    if (payload.hasPlantId) {
      this.inProgressMaintenanceByPlant = payload.MaintenanceSchedules;
      this.inProgressMaintByPlantPagination = payload.Pagination;
    } else {
      this.inProgressMaintenanceSchedules = payload.MaintenanceSchedules;
      this.inProgressMaintenancePagination = payload.Pagination;
    }
  }

  @Mutation
  private SET_MAINTENANCE_SCHEDULE(result: MaintenanceSchedule): void {
    this.maintenanceSchedule = result;
  }

  // getters
  // Get all data or by companyId data
  /** user's role = "master_admin", get all data. user's role = other than "master_admin", get by companyId data. */
  get GET_PENDING_MAINTENANCE_SCHEDULES(): MaintenanceSchedule[] {
    return this.pendingMaintenanceSchedules;
  }
  /** Pagination data for all data or acquired with companyId data */
  get GET_PENDING_MAINTENANCE_PAGINATION(): IPagination {
    return this.pendingMaintenancePagination;
  }
  /** user's role = "master_admin", get all data. user's role = other than "master_admin", get by companyId data. */
  get GET_IN_PROGRESS_MAINTENANCE_SCHEDULES(): MaintenanceSchedule[] {
    return this.inProgressMaintenanceSchedules;
  }
  /** Pagination data for all data or acquired with companyId data */
  get GET_IN_PROGRESS_MAINTENANCE_PAGINATION(): IPagination {
    return this.inProgressMaintenancePagination;
  }
  /** Get in coming or in progress maintenance data (all data) */
  get GET_MAINTENANCE_SCHEDULE(): Partial<MaintenanceSchedule> {
    return this.maintenanceSchedule;
  }

  // Get by plantId data
  /** get by plantId data */
  get GET_PENDING_MAINT_BY_PLANT(): MaintenanceSchedule[] {
    return this.pendingMaintenanceByPlant;
  }
  /** Pagination data for maintenance data acquired with plantId data */
  get GET_PENDING_MAINT_BY_PLANT_PAGINATION(): IPagination {
    return this.pendingMaintByPlantPagination;
  }
  /** get by plantId data */
  get GET_IN_PROGRESS_MAINT_BY_PLANT(): MaintenanceSchedule[] {
    return this.inProgressMaintenanceByPlant;
  }
  /** Pagination data for maintenance data acquired with plantId data */
  get GET_IN_PROGRESS_MAINT_BY_PLANT_PAGINATION(): IPagination {
    return this.inProgressMaintByPlantPagination;
  }
}

export default getModule(MaintenanceSchedules);
