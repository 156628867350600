<template>
  <el-container>
    <el-header><MenuBar :activeIndexProps="activeIndex"></MenuBar></el-header>
    <el-main>
      <PlantFormVue></PlantFormVue>
    </el-main>
  </el-container>
</template>

<script>
import MenuBar from "@/components/MenuBar.vue";
import PlantFormVue from "@/components/plants/PlantForm.vue";
import { provide, ref } from "vue";
export default {
  components: {
    MenuBar,
    PlantFormVue,
  },
  setup() {
    const state = ref();
    const mode = "create";
    provide("mode", mode);
    const activeIndex = "4";
    return {
      state,
      activeIndex,
    };
  },
};
</script>
