import {  Module, getModule, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import axios from 'axios';

@Module({ dynamic: true, store, name: 'addresses', namespaced: true })
class Addresses extends VuexModule {
  private zipResult: any | null = null;

  @Action({})
  public async queryZip(zipCode: string) {
    // eslint-disable-next-line no-useless-catch
    try {
      const requestString = `https://apis.postcode-jp.com/api/v3/postcodes/${zipCode}?apiKey=${process.env.VUE_POSTCODEJP_API_KEY}`;
      const result = await axios.get(requestString);
      this.SET_RESULT(result.data);
    } catch (error) {
      throw error;
    }
  }

  // mutation
  @Mutation
  private SET_RESULT(result: any) {
    this.zipResult = result;
  }

  // getter
  get getResult() {
    return this.zipResult;
  }
}

export default getModule(Addresses);
