import { DisPlayDuration, Duration } from "./../../types/shared/chart";

export const durationConstant = (
  duration: DisPlayDuration
): string | undefined => {
  switch (duration) {
    case Duration.HOUR:
      return "1時間";
    case Duration.DAY:
      return "1日";
    case Duration.MONTH:
      return "1ヶ月";
    case Duration.THREE_MONTHS:
      return "3ヶ月";
    case Duration.YEAR:
      return "1年";
  }
};
