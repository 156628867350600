/** Retrieves the smallest value of the specified key from the array and returns the object. */
export function findObjectWithMinValue<T>(arr: T[], key: keyof T): T {
  let minValue = arr[0][key];
  let minObject = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][key] < minValue) {
      minValue = arr[i][key];
      minObject = arr[i];
    }
  }
  return minObject;
}

/** Retrieves the biggest value of the specified key from the array and returns the object. */
export function findObjectWithMaxValue<T>(arr: T[], key: keyof T): T {
  let biggestValue = arr[0][key];
  let biggestObject = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i][key] > biggestValue) {
      biggestValue = arr[i][key];
      biggestObject = arr[i];
    }
  }
  return biggestObject;
}
