export type DisPlayDuration =
  | "hour"
  | "day"
  | "week"
  | "month"
  | "threeMonths"
  | "year";

export const Duration = {
  HOUR: "hour",
  DAY: "day",
  WEEk: "week",
  MONTH: "month",
  THREE_MONTHS: "threeMonths",
  YEAR: "year",
};
