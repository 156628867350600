import { DateTime } from "luxon";

export type SensorInputKind =
  typeof SensorInputKind[keyof typeof SensorInputKind];
export const SensorInputKind = {
  AI: "ai",
  DI: "di",
} as const;
export interface SensorLogsRequestParameters {
  kind: "ai" | "di";
  index: number;
  from: number;
  to: number;
}

export interface SensorLogsRequestParams {
  plantId?: string;
  kind?: string;
  index?: string;
  from?: number;
  to?: number;
  reportType?: string;
}

export interface HourlyData {
  hour?: number;
  data?: any;
}

export interface ReportsRequestParameters {
  plantId: string;
  from: number;
  to: number;
  reportType: string;
  kind: "ai" | "di";
  index: string;
}

export interface ReportResponse {
  Date: string;
  Logs: ReportLog[];
}

export interface NearestSensorLogs {
  Index?: number;
  Min?: RawSensorLogs;
  Max?: RawSensorLogs;
}
export interface ReportLog {
  Index: [];
  Key: string;
  Max: [];
  Min: [];
  MaxAddition: number;
  MinAddition: number;
  Result: number;
}

export interface SensorLogs {
  PlantId?: string;
  Imsi?: string;
  Index?: number;
  Kind?: "ai" | "di";
  Ts?: number; // TimeStamp
  Value?: number;
  NearestData?: number;
  CreatedAt?: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
  ModifiedAt?: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
}

export interface SensorLogsForMonitor {
  plantId: string;
  imsi: string;
  index: number;
  kind: "ai" | "di";
  // TODO: なぜstringになっているのか調査する
  ts: string; // TimeStamp
  value: number;
  createdAt?: object; // Empty objects
  modifiedAt?: object; // Empty objects
}

export interface RawSensorLogs {
  PlantId?: string;
  Imsi?: string;
  Index?: number;
  Kind?: "ai" | "di";
  Ts?: number; // TimeStamp
  Value?: number;
  CreatedAt?: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
  ModifiedAt?: string; // ISO 8601 format(yyyy-MM-ddThh:mm:ssZ)
}

export interface SoldEnergyCSV {
  date?: string;
  time?: string;
  totalSoldEnergy?: number | null;
  hourlySoldEnergy?: number | null;
}

export interface ReportCsv {
  date: string;
  energy?: number;
  oil?: number;
  oilReturn?: number;
}

export interface HourlyValue {
  hour?: number;
  value?: number | null;
  isNearest: boolean;
}

export interface FilteredSensorLog {
  timestamp?: number;
  logs?: SensorLogs[];
}

export interface SplitedDateTime {
  from: number,
  to: number,
}