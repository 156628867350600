import { SensorCode, SensorCodes } from '@/types/device';
type SensorCodeType = {
  sensorName: string;
  unit?: string;
}

const sensorCodeDic: { [key: string]: SensorCodeType } = {
  [SensorCodes.FIRE_ISSUE]: { sensorName: '火災' },
  [SensorCodes.RUNNING]: { sensorName: '運転信号' },
  [SensorCodes.ANY_ERROR]: { sensorName: '異常一括' },
  [SensorCodes.LOW_BATTERY]: { sensorName: 'バッテリー電圧低下' },
  [SensorCodes.OUTPUT_AMPERE]: { sensorName: '出力電流', unit: 'A' },
  [SensorCodes.OUTPUT_VOLTAGE]: { sensorName: '出力電圧', unit: 'V' },
  [SensorCodes.OUTPUT_WATT]: { sensorName: '出力電力', unit: 'kW' },
  [SensorCodes.OUTPUT_FREQUENCY]: { sensorName: '出力周波数', unit: 'Hz' },
  [SensorCodes.ENGINE_OIL_PRESSURE]: { sensorName: 'エンジン油圧', unit: 'kPa' },
  [SensorCodes.BATTERY_VOLTAGE]: { sensorName: 'バッテリー電圧', unit: 'V' },
  [SensorCodes.BEARING_TEMPERATURE]: { sensorName: '軸受温度', unit: '°C' },
  [SensorCodes.EXHAUST_GAS_TEMPERATURE]: { sensorName: '排気温度', unit: '°C' },
  [SensorCodes.ENGINE_OIL_TEMPERATURE]: { sensorName: 'エンジン油温', unit: '°C' },
  [SensorCodes.ENGINE_WATER_TEMPERATURE]: { sensorName: 'エンジン水温', unit: '°C' },
  [SensorCodes.STOP_START]: { sensorName: '始動/停止' },
  [SensorCodes.EMERGENCY_STOP]: { sensorName: '非常停止' },
  [SensorCodes.SYSTEM_ERROR]: { sensorName: '系統異常' },
  [SensorCodes.PLANT_TANK_LOWLEVEL_DANGER]: { sensorName: '燃料残量低下 (補充警告)' },
  [SensorCodes.PLANT_TANK_LOWLEVEL]: { sensorName: 'タンク残量', unit: '%' },
  [SensorCodes.FLOWMETER_FEED]: { sensorName: '流量計 (送り)', unit: 'L/h' },
  [SensorCodes.FLOWMETER_BACK]: { sensorName: '流量計 (戻り)', unit: 'L/h' },
  [SensorCodes.INDOOR_TEMPERATURE]: { sensorName: '室内温度', unit: '°C' },
  [SensorCodes.OUTDOOR_TEMPERATURE]: { sensorName: '室外温度', unit: '°C' },
};

export const sensorCodeConstant = (
  sensorCode: SensorCode
): SensorCodeType => {
  if (Object.keys(sensorCodeDic).indexOf(sensorCode) != -1) {
    return sensorCodeDic[sensorCode];
  } else {
    throw new Error(`sensorCode "${sensorCode}" is invalid value.`);
  }
}
