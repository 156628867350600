import * as yup from "yup";

const baseSchema = yup.object({
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
});

const lowerCaseLocationBase = yup.object({
  zipCode: yup
    .string()
    .required("郵便番号を入力してください。")
    .matches(/^[0-9]{3}-[0-9]{4}$/, "郵便番号が間違っています")
    .label("Post Code"),
  prefectureCode: yup
    .number()
    .required("都道府県を選択してください。")
    .label("Prefecture"),
  address1: yup
    .string()
    .max(50, "50文字以下で入力してください。")
    .required("住所を入力してください。")
    .label("Address1"),
  address2: yup
    .string()
    .nullable()
});

const upperCaseLocationBase = yup.object({
  ZipCode: yup
    .string()
    .required("郵便番号を入力してください。")
    .matches(/^[0-9]{3}-[0-9]{4}$/, "郵便番号が間違っています")
    .label("Post Code"),
  PrefectureCode: yup
    .number()
    .required("都道府県を選択してください。")
    .label("Prefecture"),
  Address1: yup
    .string()
    .max(50, "50文字以下で入力してください。")
    .required("住所を入力してください。")
    .label("Address1"),
  Address2: yup
    .string()
    .nullable()

});


export const loginSchema = baseSchema.shape({
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("Password"),
});

export const changePasswordSchema = baseSchema.shape({
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("Password"),
  newPassword: yup
    .string()
    .required("新しいパスワードを入力してください。")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "8文字以上・大文字・小文字・記号・数字を入力してください"
    )
    .label("New Password"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "パスワードの一致が必要です"),
});

export const emailSchema = baseSchema.shape({
});

export const forgotPasswordSubmit = baseSchema.shape({
  code: yup
    .string()
    .required("コードを入力してください。")
    .label("Confirmation Code"),
  password: yup
    .string()
    .required("新しいパスワードを入力してください。")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "8文字以上・大文字・小文字・記号・数字を入力してください"
    )
    .label("New Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "パスワードの一致が必要です"),
});

const baseMaintenanceScheduleSchema = yup.object({
  PlantId: yup.string().required("発電所を選択して下さい。"),
  CompanyId: yup.string().required("メンテナンス業者を選択して下さい。"),
  PlantSensorNodeImsi: yup.string().when("PlantId", {
    is: "",
    otherwise: (schema) =>
      schema.required(
        "この発電所は Plant Sensor Node IMSI が登録されていません。"
      ),
  }),
  PlantSensorNodeBlockName: yup.string().required("発電機名を選択して下さい。"),
  MaintenanceEndAt: yup
    .date()
    .typeError(
      `終了日は'yyyy-MM-dd' / 終了時間は'hh:mm'の形式で入力してください。`
    )
    .min(
      yup.ref("MaintenanceStartAt"),
      `メンテナンス終了予定日時は開始予定日時以降に設定してください`
    )
    .required("メンテナンス終了予定日を選択して下さい。"),
});

export const createMaintenanceScheduleSchema =
  baseMaintenanceScheduleSchema.shape({
    MaintenanceStartAt: yup
      .date()
      .typeError(
        `開始日は'yyyy-MM-dd' / 開始時間は'hh:mm'の形式で入力してください。`
      )
      .required("メンテナンス開始予定日を選択して下さい。")
      .when("Status", {
        is: "past",
        otherwise: (schema) =>
          schema.min(
            new Date(),
            "メンテナンス開始日時は現在の日時以降に設定してください。"
          ),
      }),
  });

export const updateMaintenanceScheduleSchema =
  baseMaintenanceScheduleSchema.shape({
    MaintenanceStartAt: yup
      .date()
      .typeError(
        `開始日は'yyyy-MM-dd' / 開始時間は'hh:mm'の形式で入力してください。`
      )
      .required("メンテナンス開始予定日を選択して下さい。"),
    Status: yup.string().required("対応状況を選択して下さい。"),
  });

export const plantDevicesSchema = yup.object({
  psnRegistration: yup
    .array()
    .of(
      yup.object({
        Imsi: yup
          .string()
          .required("IMSIを選択してください")
          .label("IMSI"),
        Name: yup
          .string()
          .required("名称を入力してください")
          .label("名称"),
        Tanks: yup
          .array()
          .of(
            yup.object({
              Name: yup.string().required("タンク名を入力してください"),
            })
          ),
        Generators: yup
          .array()
          .of(
            yup.object({
              Name: yup.string().required("発電機名を入力してください"),
              //Commented out and added above line to fix BIOM-1000, new plants added without name
              // IsEnabled: yup.bool(),
              // Name: yup.string().when('IsEnabled', {
              //   is: true,
              //   then: yup.string().required(),
              // })
            })
          )
      })
    ).strict()
});
export const userSchema = yup.object({
  companyId: yup
    .string()
    .required("所属会社を選択してください。")
    .label("Company"),
  name: yup
    .string()
    .max(20, "２０文字以下で入力してください。")
    .required("名前を入力してください。")
    .label("Name"),
  nameKana: yup
    .string()
    .matches(/^[ァ-ヶ０-９ー]*$/, "カタカナで入力してください")
    .max(20, "２０文字以下で入力してください。")
    .required("カナを入力してください。")
    .label("Kana"),
  emailAddress: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
  role: yup
    .string()
    .required("権限を選択してください。")
    .label("Role"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "8文字以上・大文字・小文字・記号・数字を入力してください"
    )
    .nullable()
    .label("password"),
  passwordConfirm: yup.string().ensure().when('password', {
    is: '',
    then: yup.string().oneOf([yup.ref("password"), null], "パスワードの一致が必要です")
  }),
})

export const companySchema = upperCaseLocationBase.shape({
  Name: yup
    .string()
    .max(50, "50文字以下で入力してください。")
    .required("名前を入力してください。")
    .label("Name"),
  NameKana: yup
    .string()
    .matches(/^[ァ-ヶ０-９ー]*$/, "カタカナで入力してください")
    .max(50, "50文字以下で入力してください。")
    .required("カナを入力してください。")
    .label("Kana"),
  RepresentativeName: yup
    .string()
    .required("代表者を入力してください。")
    .label("Representative"),
  Type: yup
    .mixed()
    .required("会社区分を入力してください。")
    .label("Classification"),
  EmailAddress: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, "メールアドレスを正しく入力してください。")
    .required("メールを入力してください。")
    .email("メールアドレスの形式で入力してください。")
    .label("Email"),
  PhoneNumber: yup
    .string()
    .matches(/^0\d{1,3}-\d{2,4}-\d{3,4}$/, "有効な電話番号をハイフン（-）付きで入力してください。")
    .required("電話番号を入力してください。")
    .label("Phone"),
  FaxNumber: yup
    .string()
    .matches(/^0\d{1,3}-\d{2,4}-\d{3,4}$|^$/, "有効なFAX番号をハイフン（-）付きで入力してください。")
    .nullable()
    .label("Fax")
});

export const plantInfoSchema = lowerCaseLocationBase.shape({
  name: yup
    .string()
    .max(50, "50文字以下で入力してください。")
    .required("名前を入力してください。")
    .label("Name"),
  nameKana: yup
    .string()
    .max(50, "50文字以下で入力してください。")
    .matches(/^[ァ-ヶ０-９ー]*$/, "カタカナで入力してください")
    .required("カナを入力してください。")
    .label("Kana"),
  latitude: yup
    .number()
    .typeError("数値を入力してください。")
    .notOneOf([0], "緯度を入力してください。")
    .required("緯度を入力してください。")
    .label("Latitude"),
  longitude: yup
    .number()
    .notOneOf([0], "緯度を入力してください。")
    .typeError("数値を入力してください。")
    .required("経度を入力してください。")
    .label("Longitude"),

});

export const plantActorSchema = yup.object({
  installationCompany: yup
    .array().of(yup.string())
    .min(1, "設置⼯事事業者を選択してください。")
    .required("設置⼯事事業者を入力してください。")
    .label("Instalation Company"),
  adminCompany: yup
    .array().of(yup.string())
    .min(1, "管理者を選択してください。")
    .required("管理者を選択してください。")
    .label("Admin Company"),
  ownerCompany: yup
    .array().of(yup.string())
    .min(1, "事業者を選択してください。")
    .required("事業者を選択してください。")
    .label("Owner Company"),
  monitoringCompany: yup
    .array().of(yup.string().required())
    .min(1, "監視会社を選択してください。")
    .label("Monitoring Company"),
})

// NOTE: priceLogsで使用していたschemaです。使用が未定ですが、今後使用する際はコメントアウトを解除して下さい。
// export const plantPriceSchema = yup.object({
  // maintenanceCostPercentage: yup
  //   .number()
  //   .nullable()
  //   .typeError("数値を入力してください。")
  //   .test(
  //     'is-decimal',
  //     'invalid decimal',
  //     (value: any): boolean => {
  //       if(value != undefined){
  //         return /^\d+(\.\d{0,3})?$/.test(value);
  //       }
  //       return true;
  //     }
  //   )
  //   .max(9.999, "9.99以下の数字を入力してください。")
  //   .label("maintenanceCostPercentage"),
  // powerSalesAgreement: yup
  //   .string()
  //   .nullable()
  //   .label("powerSalesAgreement"),
  // oilFee: yup
  //   .number()
  //   .positive()
  //   .nullable()
  //   .typeError("数値を入力してください。")
  //   .max(999999999, "999999999以下の数字を入力してください。")
  //   .label("oilFee"),
// })

export const oilPriceLogSchema = yup.object({
  PlantId: yup
    .string()
    .typeError("文字列を入力してください。")
    .required("発電所名を選択してください。")
    .label("PlantId"),
  Distributor: yup
    .string()
    .typeError("文字列を入力してください。")
    .required("卸業者を入力してください。")
    .label("Distributor"),
  OrderDate: yup
    .string()
    .typeError("文字列を入力してください。")
    .required("購入日を選択してください。")
    .label("OrderDate"),
  OrderAmount: yup
    .number()
    .integer("整数を入力してください。")
    .typeError("数値を入力してください。")
    .max(999999999, "999999999以下の数字を入力してください。")
    .required("購入量を入力してください。")
    .min(1, "購入量を入力してください。")
    .label("OrderAmount"),
  TotalPurchase: yup
    .number()
    .integer("整数を入力してください。")
    .typeError("数値を入力してください。")
    .max(999999999, "999999999以下の数字を入力してください。")
    .min(1, "購入金額を入力してください。")
    .required("購入金額を入力してください。")
    .label("TotalPurchase"),
})

export const salesPriceLogSchema = yup.object({
  price: yup
    .number()
    .max(99999, "99999以下の数値を入力してください。")
    .moreThan(0, "0より大きい数値を入力してください")
    .typeError("数値を入力してください。")
    .required("売電価格を入力してください。")
    .label("price"),
  startAt: yup
    .date()
    .typeError(`'yyyy-MM-dd'の形式で入力してください。`)
    .required("売電価格変更日を入力してください。")
    .label("startAt"),
})

export const alertSchema = yup.object({
  Comment: yup
    .string()
    .nullable()
    .label("Comment"),
  Priority: yup
    .mixed().oneOf(['high', 'normal', 'low', 'none'])
    .required("優先度を選択してください。")
    .label("Priority"),
  NewStatus: yup
    .mixed().oneOf(['pending', 'processing', 'complete', null])
    .nullable()
    .label("NewStatus"),
  NewDueDate: yup
    .string()
    .nullable()
    .label("NewDueDate"),
})

export const remoteControlPasswordSchema = yup.object({
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("password"),
})

export const remoteControlOutputSchema = yup.object({
  executeTime: yup
    .date()
    .min(new Date(), '実行時間は現在の時間より後でなければなりません。')
    .required("パスワードを入力してください。")
    .label("executeTime"),
  password: yup
    .string()
    .required("パスワードを入力してください。")
    .label("password"),
  powerOutput: yup
    .number()
    .max(700, "700以下の数値を入力してください。")
    .moreThan(0, "0より大きい数値を入力してください")
    .typeError("数値を入力してください。")
    .required("変更出力を入力してください。")
    .label("powerOutput"),
})