import { TimelineAnalysisData } from "@/types/timeline";
import { DateTime } from "luxon";

const dataKeys = ["energy-feed", "oil-consumption"];

const buildAnalysisPlantChartData = (
  timePeriod: string,
  data: TimelineAnalysisData[],
  startUnixSec: number,
  endUnixSec: number
) => {
  const result = [];
  const from = DateTime.fromMillis(startUnixSec * 1000);
  const to = DateTime.fromMillis(endUnixSec * 1000);

  if (!data || data.length <= 0) {
    return [[], []];
  }

  switch (timePeriod) {
    case "day": {
      let dateTime = to.startOf("hour").minus({ days: 1 });
      for (let i = 0; i <= 24; i++) {
        const dataCurrentHour = data.filter((ana) => {
          const anaTime = DateTime.fromSeconds(Number(ana.Ts));
          return anaTime.hasSame(dateTime, "hour");
        });

        const plantAnalyses = dataKeys.map((key) => {
          const values: number[] = [];
          dataCurrentHour.forEach((i: any) => {
            const analysis = i.PlantAnalysis.find((analysis: any) => {
              return analysis.Key === key;
            });
            if (analysis && analysis.Value !== null) {
              values.push(analysis.Value);
            }
          });
          const total = values.reduce((a, b) => {
            return a + b;
          }, 0);

          const average = total / values.length;
          return {
            Key: key,
            Value: average >= 0 ? average : null,
          };
        });

        result.push({
          PlantAnalysis: plantAnalyses,
          CreatedAt: dateTime,
        });
        dateTime = dateTime.plus({ hours: 1 });
      }
      break;
    }

    case "month": {
      const daysInMonth = to.minus({ months: 1 }).daysInMonth;
      let dateTime = from;
      for (let i = 0; i <= daysInMonth; i++) {
        const dataCurrentDay = data.filter((ana) => {
          const anaTime = DateTime.fromSeconds(Number(ana.Ts));
          const isSame = anaTime.hasSame(dateTime, "day");
          return isSame;
        });

        // just get each key
        const plantAnalyses = data[0].PlantAnalysis.map((item: any) => {
          const values: number[] = [];
          dataCurrentDay.forEach((i: any) => {
            const analysis = i.PlantAnalysis.find((analysis: any) => {
              return analysis.Key === item.Key;
            });
            if (analysis && analysis.Value !== null) {
              values.push(analysis.Value);
            }
          });
          const total = values.reduce((a, b) => {
            return a + b;
          }, 0);

          const average = total / values.length;
          return {
            Key: item.Key,
            Value: average >= 0 ? average : null,
          };
        });

        result.push({
          PlantAnalysis: plantAnalyses,
          CreatedAt: dateTime,
        });

        dateTime = dateTime.plus({ days: 1 });
      }

      break;
    }
  }

  const wattageValues = result.map((t) => {
    const value = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === "energy-feed"
    );
    return {
      x: new Date(Math.round(t.CreatedAt.toSeconds() * 1000)),
      y: value ? value.Value : null,
    };
  });

  const oilConsumptionValues = result.map((t) => {
    const value = t.PlantAnalysis.find(
      (a: { Key: string }) => a.Key === "oil-consumption"
    );
    return {
      x: new Date(Math.round(t.CreatedAt.toSeconds() * 1000)),
      y: value ? value.Value : null,
    };
  });

  return [wattageValues, oilConsumptionValues];
};

export default buildAnalysisPlantChartData;
