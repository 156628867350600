import { AccessControlsResponse } from "@/types/accessControl";

const getCrudPermission = (acl: AccessControlsResponse[] | null, functionName: string) => {
    let canCreate = false;
    let canViewDetail = false;
    let canViewAll = false;
    let canUpdate = false;
    let canDelete = false;
    if (!acl) {
        return [canCreate, canViewDetail, canViewAll, canUpdate, canDelete];
    }
    canCreate = acl?.find((element) => element.FunctionId == functionName + ".create")?.IsPermitted || false;
    canViewDetail = acl?.find((element) => element.FunctionId == functionName + ".detail")?.IsPermitted || false;
    canViewAll = acl?.find((element) => element.FunctionId == functionName + ".getList")?.IsPermitted || false;
    canUpdate = acl?.find((element) => element.FunctionId == functionName + ".edit")?.IsPermitted || false;
    canDelete = acl?.find((element) => element.FunctionId == functionName + ".delete")?.IsPermitted || false;
    return [canCreate, canViewDetail, canViewAll, canUpdate, canDelete]
    
}

export default getCrudPermission;