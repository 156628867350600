import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';

import store from '@/store';
import { http } from '@/http/http';
import { CompaniesRequestParameters, Company, CreateCompanyRequestBody } from '@/types/company';
import { IPagination } from '@/types/pagination';

@Module({ dynamic: true, store, name: 'companies', namespaced: true })
class Companies extends VuexModule {
  // state
  private companies: Company[] = [];
  private singleCompany: any = {};
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private errorMessage = {
    field: "" as any,
    msg: ""
  };

  // action
  @Action({ rawError: true })
  public async fetchCompanies(params: CompaniesRequestParameters) {
    const result = await http.get('/companies', params);
    this.SET_COMPANIES({ Companies: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fecthCompany(id: string) {
    const result = await http.get(`/companies/${id}`);
    this.SET_COMPANY(result);
  }

  @Action({ rawError: true })
  public async createCompany(payload: { body: CreateCompanyRequestBody }) {
    try {
      await http.post('/companies', payload.body);
      this.SET_ERROR_MESSAGE({field: "", msg: ""});
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
      this.SET_ERROR_MESSAGE(errorMessage);
    }
    
  }

  @Action({ rawError: true })
  public async updateCompany(payload: {
    id: string;
    body: Partial<CreateCompanyRequestBody>;
  }) {
    try {
      await http.put(`/companies/${payload.id}`, payload.body);
      this.SET_ERROR_MESSAGE({field: "", msg: ""});
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
      this.SET_ERROR_MESSAGE(errorMessage);
    }
    
  }

  @Action({ rawError: true })
  public async deleteCompany(id: string) {
    await http.delete(`/companies/${id}`);
  }

  @Mutation
  private SET_COMPANIES(payload: {
    Pagination: IPagination;
    Companies: Company[];
  }): void {
    this.companies = payload.Companies;  
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_COMPANY(company: Company): void {
    this.singleCompany[company.Id] = company;
  }
  @Mutation
  private SET_ERROR_MESSAGE(message: any): void {
    this.errorMessage = message;
  }

  @Action({})
  public async ErrorMessageHandling(error:string) {
    let message = {
      field: "",
      msg: "エラーが発生されました"
    }
    
    switch (error) {
      case "duplicate key value violates unique constraint \"companies_name_key\"":
        message  = {
          field: "Name",
          msg: "会社名は既に使用されています。別の会社名で登録してください。"
        };
        break;
      case "duplicate key value violates unique constraint \"companies_email_address_key\"":
        message  = {
          field: "EmailAddress",
          msg: "メールアドレスは既に使用されています。別のメールアドレスで登録してください。"
        };
        break;
      // Add more error message here
      default:
        break;
    }
    return message;
 }

  get GET_COMPANIES(): Company[] {
    return this.companies;
  }

  get GET_COMPANY_LOOKUP(): any {
    return this.singleCompany;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }

  get GET_ERROR_MESSAGE() {
    return this.errorMessage;
  }
}

export default getModule(Companies);
