import {
  MonitoringConfig,
  MonitorConfigRequestParameters,
  CreateMonitoringConfigRequestBody,
} from "./../../types/monitoring";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";

@Module({
  dynamic: true,
  store,
  name: "user-monitoring-configs",
  namespaced: true,
})
class MonitoringConfigs extends VuexModule {
  // state
  private monitoringConfigs: MonitoringConfig | null = null;

  @Action({ rawError: true })
  public async fetchMonitoringConfigs(
    queryParams: MonitorConfigRequestParameters
  ): Promise<void> {
    const response = await http.get(
      `/users/${queryParams.userId}/monitoring-configs`,
      queryParams
    );
    this.SET_MONITORING_CONFIGS(response.Data);
  }

  @Action({ rawError: true })
  public async createMonitoringConfig(payload: {
    userId: string;
    body: Partial<CreateMonitoringConfigRequestBody>;
  }) {
    const result: MonitoringConfig = await http.post(
      `/users/${payload.userId}/monitoring-configs`,
      payload.body
    );
    return result;
  }

  @Action({ rawError: true })
  public async updateMonitoringConfig(payload: {
    id: number;
    body: Partial<CreateMonitoringConfigRequestBody>;
  }) {
    const result: MonitoringConfig = await http.put(
      `/users/${payload.body.UserId}/monitoring-configs/${payload.id}`,
      payload.body
    );
    return result;
  }

  @Mutation
  private SET_MONITORING_CONFIGS(data: MonitoringConfig | null): void {
    this.monitoringConfigs = data;
  }

  get GET_MONITORING_CONFIGS(): MonitoringConfig | null {
    return this.monitoringConfigs;
  }
}
export default getModule(MonitoringConfigs);
