import { SensorInputKind } from "@/types/sensorLog";

export type LayoutMode = typeof LayoutMode[keyof typeof LayoutMode];
export const LayoutMode = {
  PLANE: "plane",
  TWO_COL: "2col",
} as const;

export interface MonitorSensorsConfig {
  PlantId: string;
  Sensors: {
    Kind: SensorInputKind;
    Index: number;
  }[];
  SortKey: number;
}

export interface MonitoringConfig {
  Id: number;
  UserId: string;
  LayoutMode: LayoutMode;
  Payload: MonitorSensorsConfig[];
  CreatedAt: string; // format: ISO 8601(yyyy-MM-ddThh:mm:ssZ)
  ModifiedAt: string; // format: ISO 8601(yyyy-MM-ddThh:mm:ssZ)
}

export interface MonitorConfigRequestParameters {
  pageSize: number;
  page: number;
  userId: string;
}

export interface CreateMonitoringConfigRequestBody {
  UserId: string;
  LayoutMode: LayoutMode;
  Payload: MonitorSensorsConfig[];
}
