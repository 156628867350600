import { Company } from "./company";

export interface UsersRequestParameters {
  page?: number;
  pageSize?: number;
  company?: string;
  name?: string;
  nameKana?: string;
  email?:string;
  role?: string;
  sort?: "name" | "nameKana" | "prefectureCode" | "role" | "companyName";
  sortOrder?: "ASC" | "DESC";
}

export const UserRole = {
  MASTER_ADMIN: 'master_admin',
  GROUP_ADMIN: 'group_admin',
  GROUP_USER: 'group_user',
  OWNER: 'owner',
}
interface Location {
    ZipCode: string;
    PrefectureCode: number;
    Address1: string;
    Address2: string;
}

export interface CreateUserRequestBody {
    id?: string;
    name?: string;
    nameKana?: string;
    role?: string;
    emailAddress?: string;
    companyId?: string;
    prefectureCode?: string;
    address1?: string;
    address2?: string;
    facsimileNumber?: string;
    telePhoneNumber?: string;
    zipCode?: string;
}

export interface User {
    Id: string;
    Name?: string;
    NameKana?: string;
    Location?: Location;
    EmailAddress?: string;
    TelephoneNumber?: string;
    FacsimileNumber?: string;
    Role?: string;
    Company?: Company;
    CreatedAt?: number;
    ModifiedAt?: number;
    IsVerified?: boolean;
}

export interface UserInfo {
    email: string;
    password: string;
  }
  
  export interface PasswordChangeInfo {
    email: string;
    password: string;
    newPassword: string;
    confirmNewPassword: string;
  }

  export interface ForgotPasswordInfo {
    email: string;
  }
  export interface ForGotPasswordSubmitInfo {
    email: string;
    code: string;
    password: string;
    confirmPassword: string;
  }
  
