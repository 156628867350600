import { NearestSensorLogs } from "@/types/sensorLog";

/** The sensor data in this index is used to calculate the total value. */
export const getIndexNumberByStr = (indexStr: string) => {
  // ex. "5, 6"
  const indexStringArr = indexStr.split(",");
  // ex. [5, 6]
  const indexNumberArr = indexStringArr.map(Number);
  // ex. 6
  const highIndex = Math.max(...indexNumberArr);
  // ex. 5
  const lowIndex = Math.min(...indexNumberArr);

  return {
    highIndex: highIndex,
    lowIndex: lowIndex,
  };
};

/**
 * If the value of the L sensor data exceeds 65536, instead of being reset to 0,
 * the value of the H sensor data is increased by one.
 * the process takes two 16-bit integers of L and H sensor data and combines them into a 32-bit integer.
 */
export const calculateAdditionalData = (low: number, high: number): number => {
  return (high << 16) + low;
};

/** This Nearest sensor data is used to calculate the totalized sensor data */
export const getNearestAdditionalValue = (
  nearestData: NearestSensorLogs[],
  highIndex: number,
  lowIndex: number
) => {
  // Get data from each of the H and L sensors
  const minLow = nearestData.find((e) => e.Index === lowIndex);
  const maxLow = nearestData.find((e) => e.Index === highIndex);

  // Nearest data of L-sensor
  const minLowNearest = minLow?.Min?.Value || 0;
  const maxLowNearest = maxLow?.Max?.Value || 0;

  // Nearest data of H-sensor
  const minHighNearest = maxLow?.Min?.Value || 0;
  const maxHighNearest = maxLow?.Max?.Value || 0;

  const minAdditionData = calculateAdditionalData(
    minLowNearest,
    minHighNearest
  );
  const maxAdditionData = calculateAdditionalData(
    maxLowNearest,
    maxHighNearest
  );

  return {
    maxAdditionData: maxAdditionData,
    minAdditionData: minAdditionData,
  };
};
