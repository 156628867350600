import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { AccessControlsResponse, AcessControl } from '@/types/accessControl';
import checkPermission from '../helpers/getPermission';

  
@Module({ dynamic: true, store, name: 'accessControls', namespaced: true })
class AccessControls extends VuexModule {
  // state
  private accessControls: AccessControlsResponse[] | null = null;
  @Action({ rawError: true })
  public async fetchAccessControl(kind: string) {
    const result = await http.get(`/access-controls/${kind}`);
    const data:AccessControlsResponse[]  = result ? result["Data"] : null;
    this.SET_ACCESS_CONTROL(data); 
  }

  @Mutation
  private SET_ACCESS_CONTROL(accessControl: AccessControlsResponse[]) {
    this.accessControls = accessControl;
  }
  get GET_ACCESS_CONTROL() {
    return this.accessControls;
  }
}

export default getModule(AccessControls);
  