import { DateTime } from "luxon";

type DateType = "now" | "minute" | "day" | "month" | "year";

type DisabledDateTimeType = {
  selectedMonthDateTime: Date;
  dateType?: DateType;
  calculationDateTime?: number;
};

export const SelectedDatePicker = {
  FROM: "from",
  TO: "to",
} as const;

/**
 * This function returns an object to be used for Luxon's minus() and plus() methods.
 * Example: Used like this in Luxon => const yesterday = DateTime.now().minus({ days: 1 });
 */
const makeDateTimeObj = (dateType: DateType, calculationDateTime: number) => {
  switch (dateType) {
    case "now":
      return { minutes: 0 };
    case "minute":
      return { minutes: calculationDateTime };
    case "day":
      return { days: calculationDateTime };
    case "month":
      return { months: calculationDateTime };
    case "year":
      return { years: calculationDateTime };
    default:
      return { minutes: 0 };
  }
};

/** Converts time in 'yyyy-MM-dd HH:mm:ss' format or JS Data type to Unix time. */
const convertUnixTime = (dateTime: string | Date): number => {
  if (typeof dateTime === "string") {
    return DateTime.fromSQL(dateTime).toSeconds();
  } else if (typeof dateTime === "object") {
    return DateTime.fromJSDate(dateTime).toSeconds();
  } else {
    throw new Error("選択した日付が取得できません。");
  }
};

/** This function is used to restrict the input to 'from < to' when searching for 'from' and 'to' periods */
export const checkDisabledDate = (
  selectedForm: "from" | "to",
  selectedMonthDateTime: Date,
  alreadySetValue: string // format: yyyy-MM-dd HH:mm:ss
): boolean => {
  const alreadySetUnixTime = convertUnixTime(alreadySetValue);
  const selectedMonthUnixTime = convertUnixTime(selectedMonthDateTime);

  if (selectedForm === SelectedDatePicker.FROM) {
    return selectedMonthUnixTime > alreadySetUnixTime;
  } else {
    return selectedMonthUnixTime < alreadySetUnixTime;
  }
};

/**
 * This function determines how many days old data should be set to disabled.
 * Example: If the argument "dateType" = "day", data prior to yesterday's date will be disabled.
 */
export const checkDisabledPreviousDateTime = ({
  selectedMonthDateTime,
  dateType = "day",
  calculationDateTime = 1,
}: DisabledDateTimeType): boolean => {
  // Example: default output => { days: 1 };
  const dateTimeObj = makeDateTimeObj(dateType, calculationDateTime);

  const previousDateTimeToUnixTime = DateTime.now().minus(dateTimeObj).toSeconds();
  return convertUnixTime(selectedMonthDateTime) < previousDateTimeToUnixTime;
};

/**
 * This function determines how many days later data should be set to disabled.
 * Example: If the argument "dateType" = "day", data prior to yesterday's date will be disabled.
 */
export const checkDisabledLaterDateTime = ({
  selectedMonthDateTime,
  dateType = "day",
  calculationDateTime = 0,
}: DisabledDateTimeType): boolean => {
  // Example: default output => { days: 0 };
  const dateTimeObj = makeDateTimeObj(dateType, calculationDateTime);

  const laterDateTimeToUnixTime = DateTime.now().plus(dateTimeObj).toSeconds();
  return convertUnixTime(selectedMonthDateTime) > laterDateTimeToUnixTime;
};
