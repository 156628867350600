/**
 * Returns the 'priority' of alert_response_histories converted to Japanese.
 */
export const alertPriorityConstant = (
  priority: string
): string | undefined => {
  switch (priority) {
    case 'high':
      return '高';
    case 'normal':
      return '中';
    case 'low':
      return '低';
    case 'none':
      return 'なし';
  }
};