import { RawSensorLogs, ReportResponse, SensorLogs as sensorLog } from "./../../types/sensorLog";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";
import { decompress } from "compress-json";

@Module({ dynamic: true, store, name: "reports", namespaced: true })
class Reports extends VuexModule {
  // state
  private reportData: ReportResponse[] = [];

  // action
  @Action({ rawError: true })
  public async fetchReportData(params: any) {
    const response: ReportResponse[] = await http.get(
      `/plants/report`,
      params
    );
    this.SET_REPORT_DATA(response);
    return response;
  }

  @Action({ rawError: true })
  public async fetchRawSensorData(params: any) {
    const response:RawSensorLogs[] = await http.get(
      `/plants/report`,
      params
    );
    return response;
  }

  @Action({ rawError: true })
  public async fetchCompressRawSensorData(params: any) {
    const response = await http.get(
      `/plants/report`,
      params
    );
    const decompressed = decompress(response);
    return decompressed;
  }

  // mutations
  @Mutation
  private SET_REPORT_DATA(response: ReportResponse[]): void {
    this.reportData = response;
  }

  // getters
  get GET_REPORT_DATA(): ReportResponse[] {
    return this.reportData;
  }
}

export default getModule(Reports);
