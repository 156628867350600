import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { User } from '@/types/user';
import { SessionRequestParameters } from '@/types/sessions';

  
@Module({ dynamic: true, store, name: 'sessions', namespaced: true })
class Users extends VuexModule {
    // state
    private users: User[] = [];
    private singleUsers: any = {};
    private delegateToken: string|null = null;


    @Action({ rawError: true })
    public async fetchUser(id: string) {
        const user = await http.get(`/users/${id}`);
        this.SET_USER(user);
    }

    @Action({ rawError: true })
    public async createSession(payload: { body: SessionRequestParameters }) {
        const sessionInfo = await http.post('/session', payload.body);
        this.SET_DELEGATE_TOKEN(sessionInfo.DelegateToken);
    }
    
    @Mutation
    private SET_USER(user: User): void {
        this.singleUsers[user.Id] = user;
    }

    @Mutation
    private SET_DELEGATE_TOKEN(token: string): void {
        this.delegateToken = token;
    }

    // getters
    get GET_USERS(): User[] {
        return this.users;
    }

    get GET_USER_LOOKUP(): any {
        return this.singleUsers;
    }
    get GET_DELEGATE_TOKEN(): string|null {
        return this.delegateToken;
    }
}

export default getModule(Users);
  