import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "./plugins/luxon";
import "chartjs-adapter-luxon";
import "@/plugins/formValidationConfig";
import ElementPlus from 'element-plus';
import elementJaLocale from 'element-plus/lib/locale/lang/ja'
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import JsonCSV from 'vue-json-csv';
import L from 'leaflet';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
Amplify.configure(awsExports);
const app = createApp(App)
// For loading all el-icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// For csv output ('vue-json-csv' library)
app.component('downloadCsv', JsonCSV);

app.use(ElementPlus, {
    locale: elementJaLocale,
})
app.use(store)
app.use(router)
app.mount('#app');
