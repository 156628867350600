//<reference path="path/types.d.ts" />
import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { Plant, PlantsRequestParameters, Prefectures } from '@/types/plant';
import { IPagination } from '@/types/pagination';
import { DateTime } from 'luxon';
  
@Module({ dynamic: true, store, name: 'plants', namespaced: true })
class Plants extends VuexModule {
  // state
  private plants: Plant[] = [];
  private plantsFuelManagement: Plant[] = [];

  private errorMessage = '';
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private plant: Plant | null = null;
  private plantObjects: { [id: string]: Plant } = {};
  private prefectures: Prefectures[] = require(`@/utils/Prefectures.json`);

  @Action({ rawError: true })
  public async fetchPlants(queryStringParameters?: PlantsRequestParameters) {
    const result = await http.get('/plants', queryStringParameters);
    this.SET_PLANTS({ Plants: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } })

  }

  @Action({ rawError: true })
  public async fetchPlantsFuelManagement(queryStringParameters?: PlantsRequestParameters) {
    const startDate = DateTime.local().startOf("month");
    const timestampFrom = startDate.toSeconds();
    const extendedQueryParams = { ...queryStringParameters, timestampFrom: timestampFrom.toString() }
    const result = await http.get('/plants-fuel-management', extendedQueryParams);
    this.SET_PLANTS_FUEL_MANAGEMENT({ Plants: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fetchPlant(id: string) {
    const plant = await http.get(`/plants/${id}`);
    this.SET_PLANT(plant);
    this.SET_PLANT_OBJECT(plant);
  }

  @Action({ rawError: true })
  public async createPlant(body: any) {
    try {
      const result = await http.post('/plants', body);
      this.SET_PLANT(result);
      this.SET_ERROR_MESSAGE("");
    } catch (error: any) {
      this.SET_ERROR_MESSAGE("");
      const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
      this.SET_ERROR_MESSAGE(errorMessage);
    }

  }

  @Action({ rawError: true })
  public async updatePlant(params: { id: string; body: any }) {
    const result = await http.put(`/plants/${params.id}`, params.body);
    this.SET_PLANT(result);
  }

  @Action({ rawError: true })
  public async deletePlant(payload : { id: string}) {
    const response = await http.delete(`/plants/${payload.id}`);
    return response;
  }

  // mutation
  @Mutation
  private SET_PLANTS(payload: { Plants: Plant[]; Pagination: IPagination }) {
    this.plants = payload.Plants;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_PLANTS_FUEL_MANAGEMENT(payload: { Plants: Plant[]; Pagination: IPagination }) {
    this.plantsFuelManagement = payload.Plants;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_PLANT(plantData: Plant) {
    this.plant = plantData;
  }

  @Mutation
  private SET_PLANT_OBJECT(plantData: Plant) {
    if (plantData?.Id ) {
      this.plantObjects[plantData?.Id] = plantData;
    }
  }

  @Mutation
  private SET_ERROR_MESSAGE(message: string): void {
    this.errorMessage = message;
  }

  @Action({})
  public async ErrorMessageHandling(error:string) {
    let message = "エラーが発生されました"
    switch (error) {
      case "duplicate key value violates unique constraint \"ui_plants_name\"":
        message = "発電所名は既に使用されています。別の発電所名で登録してください。"  ;
        break;
      // Add more error message here
      default:
        message = "エラーが発生しました。";
        break;
    }
    return message;
 }
  get GET_PLANTS() {
    return this.plants;
  }

  get GET_PLANTS_FUEL_MANAGEMENT() {
    return this.plantsFuelManagement;
  }

  get GET_PAGINATION() {
    return this.pagination;
  }

  get GET_PLANT() {
    return this.plant;
  }

  get GET_PLANT_LOOK_UP() {
    return this.plantObjects;
  }

  get GET_ERROR_MESSAGE() {
    return this.errorMessage;
  }

}

export default getModule(Plants);
  