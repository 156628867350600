import { ElMessage } from "element-plus";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";
import { IPagination } from "@/types/pagination";
import {
  CreateSalesLogsRequestBody,
  SalesLogsRequestParameters,
  SalesPriceLog,
} from "@/types/salesPriceLogs";

@Module({
  dynamic: true,
  store,
  name: "sales-price-logs",
  namespaced: true,
})
class SalesPriceLogs extends VuexModule {
  // state
  private salesPriceLogs: SalesPriceLog[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private errorMessage = {
    field: "" as any,
    msg: "",
  };

  @Action({ rawError: true })
  public async fetchSalesPriceLogs(
    queryStringParameters?: SalesLogsRequestParameters
  ): Promise<void> {
    const response = await http.get("/sales-price-logs", queryStringParameters);
    const payload = {
      Data: response.Data,
      Pagination: { Count: response.Count, Page: response.CurrentPage },
    };
    this.SET_SALES_PRICE_LOGS(payload);
  }

  @Action({ rawError: true })
  public async createSalesPriceLog(payload: {
    body: Partial<CreateSalesLogsRequestBody>;
  }) {
    try {
      await http.post("/sales-price-logs", payload.body);
      this.SET_ERROR_MESSAGE({ field: "", msg: "" });
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(
        error.response.data.Error
      );
      this.SET_ERROR_MESSAGE(errorMessage);
    }
  }
  @Action({})
  public async ErrorMessageHandling(error: string) {
    let message = {
      field: "",
      msg: "エラーが発生されました",
    };
    if (error.includes("duplicate key value violates unique constraint")) {
      message = {
        field: "price",
        msg: "売電価格は既に登録されています",
      };
    }
    return message;
  }

  @Mutation
  private SET_SALES_PRICE_LOGS(payload: {
    Data: SalesPriceLog[];
    Pagination: IPagination;
  }): void {
    this.salesPriceLogs = payload.Data;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_ERROR_MESSAGE(message: any): void {
    this.errorMessage = message;
  }

  get GET_SALES_PRICE_LOGS(): SalesPriceLog[] {
    return this.salesPriceLogs;
  }

  get GET_PAGINATION() {
    return this.pagination;
  }

  get GET_ERROR_MESSAGE() {
    return this.errorMessage;
  }
}
export default getModule(SalesPriceLogs);
