import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { IPagination } from '@/types/pagination';
import {
  CreateOilPriceLogsRequestBody,
  OilPriceLog,
  OilPriceLogsRequestParameters
} from '@/types/oilPriceLogs';

@Module({ dynamic: true, store, name: 'oilPriceLogs', namespaced: true })
class OilPriceLogs extends VuexModule {
  // state
  private oilPriceLogs: OilPriceLog[] = [];
  private singleOilPriceLog: Partial<OilPriceLog> = {};
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({ rawError: true })
  public async fetchOilPriceLogs(params: OilPriceLogsRequestParameters) {
    const result = await http.get('/oil-price-logs', params);
    this.SET_OIL_PRICE_LOGS({ OilPriceLogs: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  
  }

  @Action({ rawError: true })
  public async fetchOilPriceLog(id: string) {
    const result = await http.get(`/oil-price-logs/${id}`);
    this.SET_OIL_PRICE_LOG(result);
    return result;
  }  

  @Action({ rawError: true })
  public async createOilPriceLog(payload: { body: Partial<CreateOilPriceLogsRequestBody>}) {
    await http.post('/oil-price-logs', payload.body);
  }

  @Action({ rawError: true })
  public async updateOilPriceLog(payload: { id: string, body: CreateOilPriceLogsRequestBody}) {
    await http.put(`/oil-price-logs/${payload.id}`, payload.body);
  }

  @Action({ rawError: true })
  public async deleteOilPriceLog(payload : { id: string}) {
    const response = await http.delete(`/oil-price-logs/${payload.id}`);
    return response;
  }

  // mutations
  @Mutation
  private SET_OIL_PRICE_LOGS(payload: {
    Pagination: IPagination;
    OilPriceLogs: OilPriceLog[];
  }): void {
    this.oilPriceLogs = payload.OilPriceLogs;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_OIL_PRICE_LOG(oilPriceLog: Partial<OilPriceLog>): void {
    this.singleOilPriceLog = oilPriceLog;
  }

  // getters
  get GET_OIL_PRICE_LOGS(): OilPriceLog[] {
    return this.oilPriceLogs;
  }

  // getters
  get GET_OIL_PRICE_LOG(): Partial<OilPriceLog> {
    return this.singleOilPriceLog;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }
}

export default getModule(OilPriceLogs);