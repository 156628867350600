import { UserRole } from "@/types/user";

type UserRoleType = {
  roleNameJP: string;
  roleNameEN: string;
};

const userRoleDic: { [key: string]: UserRoleType } = {
  [UserRole.MASTER_ADMIN]: {
    roleNameEN: "Master Admin",
    roleNameJP: "システム管理者",
  },
  [UserRole.GROUP_ADMIN]: {
    roleNameEN: "Group Admin",
    roleNameJP: "会社管理者",
  },
  [UserRole.GROUP_USER]: {
    roleNameEN: "Group User",
    roleNameJP: "ユーザー（社員）",
  },
  [UserRole.OWNER]: { roleNameEN: "Owner", roleNameJP: "事業者" },
};

export const getUserRoleName = (role: string): UserRoleType => {
  if (Object.keys(userRoleDic).indexOf(role) != -1) {
    return userRoleDic[role];
  } else {
    throw new Error(`role "${role}" is invalid value.`);
  }
};
