import { CompanyType } from "@/types/company";

export const companyTypeConstant = (type: string) => {
  switch (String(type)) {
    case CompanyType.INSTALLATION:
      return "設置⼯事事業者";
    case CompanyType.ADMIN:
      return "運営会社";
    // FIXME: こちらの仕様は未定の為、一旦下記の様に実装します。
    case CompanyType.MONITORING:
      return "監視会社";
    case CompanyType.OWNER:
      return "事業会社";
    case CompanyType.SALES:
      return "営業代行会社";
  }
};
