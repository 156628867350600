import { CompanyPlant } from './companyPlant';

export interface Company {
    Id: string;
    Name?: string;
    NameKana?: string;
    EmailAddress?: string;
    RepresentativeName?: string;
    Type?: string; // TODO: Change type to string
    ZipCode?: string;
    PrefectureCode?: number;
    Address1?: string;
    Address2?: string;
    PhoneNumber?: string;
    FaxNumber?: string;
    CompanyPlant?: CompanyPlant[];
    CreatedAt?: number;
    ModifiedAt?: number;
  }
export interface Prefectures {
    id?: number;
    name?: string;
}

export interface CompaniesRequestParameters {
    page?: number;
    pageSize?: number;
    name? : string;
    nameKana?: string;
    type?: string;
    prefectureCode?: number;
    sort?: "name" | "nameKana" | "prefectureCode" | "type";
    sortOrder?: "ASC" | "DESC";
}

export interface CreateCompanyRequestBody {
    Id?: string;
    Name?: string;
    NameKana?: string;
    EmailAddress?: string;
    RepresentativeName?: string;
    Type?: string; // TODO?: Change type to string
    ZipCode?: string;
    PrefectureCode?: number;
    Address1?: string;
    Address2?: string | null;
    PhoneNumber?: string;
    FaxNumber?: string;
}

export interface EmergencyContact {
    ownerCompanies: string[],
    adminCompanies: string[],
    installationCompanies: string[],
    monitoringCompanies: string[],
}

// FIXME: こちらの仕様は未定の為、一旦下記の様に実装します。
export const CompanyType = {
  INSTALLATION: "1", // 設置⼯事事業者
  ADMIN: "2", // 運営会社
  OWNER: "3", // 事業会社
  MONITORING: "4", // 監視会社
  SALES: "5", //営業代行会社
} as const;

export type CompanyScreenPropType = "default" | "owner" | "myPage";
export const CompanyScreenType = {
  DEFAULT: "default",
  OWNER: "owner",
  MY_PAGE: "myPage",
} as const;