import Amplify, { Auth } from "aws-amplify";
import awsExports from "@/aws-exports";
import userModule from "@/store/modules/user";
import usersModule from "@/store/modules/users";
import accessControlsModule from '@/store/modules/accessControls';

Amplify.configure(awsExports);

const AuthFilter = async (to: any, from: any, next: any) => {
  try {
    await Auth.currentAuthenticatedUser();
    const acl = accessControlsModule.GET_ACCESS_CONTROL || null;
    if(!acl || acl.length < 1) {
      await accessControlsModule.fetchAccessControl('pwc');
    }
    next();
    return;
  } catch (error) {
    if (
      to.matched.some(
        (record: { meta: { isPublic: any } }) => !record.meta.isPublic
      )
    ) {
      next({ path: "/login", query: { redirect: to.fullPath } });
      return;
    }
    next();
    return;
  }
};

export { AuthFilter };
