/**
 * Sums the values of keys in inputData and returns the result.
 */
export const getTotalData = (
  inputData: any[],
  key: string
): number => {
  return inputData.reduce((sum: number, data) => {
      return sum + Number(data[key]);
  }, 0);
}

/**
 * Sum the number of elements in the array of keys in inputData and return the result.
 */
export const getTotalLengthData = (
  inputData: {[key: string]: any},
  key: string
): number => {
  return inputData.reduce((
    sum: number, data: {[key: string]: any}
  ) => {
      return sum + Number(data[key].length);
  }, 0);
}
