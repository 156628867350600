import { AnalysisMonthItem } from "@/types/timeline";
import { DateTime } from "luxon";

interface ResultMonth {
  data: any;
  date: string;
}

const dataKeys = ["energy-feed", "oil-consumption"];

// take array of 12 months' data
const buildAnalysisPlantChartDataYear = (data: AnalysisMonthItem[]) => {
  const result: ResultMonth[] = [];

  data.forEach((month) => {
    // insert keys here
    const monthObj: any = {};

    dataKeys.forEach((key) => {
      const sum = month.data.reduce((a, b) => {
        const match = b.PlantAnalysis.find((ana) => {
          return ana.Key === key;
        });
        if (match) {
          return a + match.Value;
        } else {
          return a;
        }
      }, 0);

      monthObj[key] = sum;
    });

    result.push({
      data: monthObj,
      date: month.month,
    });
  });

  const wattageValues = result.map((month) => {
    const value = month.data["energy-feed"];
    return {
      x: new Date(month.date),
      y: value,
    };
  });

  const oilConsumptionValues = result.map((month) => {
    const value = month.data["oil-consumption"];
    return {
      x: new Date(month.date),
      y: value,
    };
  });

  return [wattageValues, oilConsumptionValues];
};

export default buildAnalysisPlantChartDataYear;
