import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import { VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';

@Module({ dynamic: true, store, name: 'alertLogPendingCount', namespaced: true  })
class AlertLogPendingCount extends VuexModule {
  // state
  private pendingStatusCount = 0;

  // action
  @Action({ rawError: true })
  public async fetchAlertPendingStatusCount(plantId: string) {
    const result = await http.get(`/alert-logs-pending-count/${plantId}`);
    this.SET_PENDING_STATUS_COUNT(result.PendingStatusCount);
  }

  // mutation
  @Mutation
  private SET_PENDING_STATUS_COUNT(
    pendingStatusCount: number
  ): void {
    this.pendingStatusCount = pendingStatusCount;
  }

  // getters 
  get GET_PENDING_STATUS_COUNT(): number {
    return this.pendingStatusCount;
  }
}

export default getModule(AlertLogPendingCount);

