import { IPagination } from '@/types/pagination';
import {
  AlertResponseHistoriesRequestParameters,
  AlertResponseHistory,
  CreateAlertResponseHistoryRequestBody
} from '@/types/alert';
import { http } from '@/http/http';
import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ dynamic: true, store, name: 'alertResponseHistories', namespaced: true })
class AlertResponseHistories extends VuexModule {
  // state
  private alertResponseHistories: AlertResponseHistory[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };

  // action
  @Action({ rawError: true })
  public async fetchAlertResponseHistories(params: AlertResponseHistoriesRequestParameters) {
    const result = await http.get(`/alert-logs/${params.alertLogPortId}/alert-response-histories`, params);
    this.SET_ALERT_RESPONSE_HISTORY({ AlertResponseHistories: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async createAlertResponseHistory(payload: { alertLogPortId: string, body: CreateAlertResponseHistoryRequestBody}) {
    const response = await http.post(`/alert-logs/${payload.alertLogPortId}/alert-response-histories`, payload.body);
    this.SET_POSTED_RESPONSE(response);
    this.context.commit('alertLogs/SET_POSTED_RESPONSE_FOR_ALERT_LOG_PORT', response, {root: true});
    return response;
  }

  @Action({ rawError: true })
  public async deleteAlertResponseHistory(payload : { alertLogPortId: string, id: string}) {
    const response = await http.delete(`/alert-logs/${payload.alertLogPortId}/alert-response-histories/${payload.id}`);
    this.SET_DELETED_RESPONSE(response);
    return response;
  }

  // mutation
  @Mutation
  private SET_ALERT_RESPONSE_HISTORY( payload: {
    AlertResponseHistories: AlertResponseHistory[];
    Pagination: IPagination;
  }): void {
    this.alertResponseHistories = payload.AlertResponseHistories;
    this.pagination = payload.Pagination;
  }

  /**
   * Processing to reflect data in the view after 'post'
   */
  @Mutation
  private SET_POSTED_RESPONSE(response: AlertResponseHistory): void {
    const proxyResponse = new Proxy( response , {});
    this.alertResponseHistories = [...this.alertResponseHistories, proxyResponse];
  }
  
  /**
   * Processing to reflect data in the view after 'delete'
   */
  @Mutation
  private SET_DELETED_RESPONSE(response: number): void {
    const index = this.alertResponseHistories.findIndex((a) => {
      return a.Id == response;
    });
    this.alertResponseHistories.splice(index, 1);
  }

  // getters
  get GET_ALERT_RESPONSE_HISTORIES(): AlertResponseHistory[] {
    return this.alertResponseHistories;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }
}

export default getModule(AlertResponseHistories);
