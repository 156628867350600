import {
    Module,
    getModule,
    VuexModule,
    Action,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { Plant } from '@/types/plant';
import { IPagination } from '@/types/pagination';
import { LiveVideo, LiveVideoDeleteRequestParameters, LiveVideoGetRequestParameters, LiveVideoPostRequestParameters } from '@/types/liveVideo';
  
@Module({ dynamic: true, store, name: 'liveVideos', namespaced: true })
class LiveVideos extends VuexModule {
  // state
  private liveVideos: LiveVideo[] = [];
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private plant: Plant | null = null;
  private liveVideo: LiveVideo | null = null;

  @Action({ rawError: true })
  public async fetchLiveVideos(queryStringParameters?: LiveVideoGetRequestParameters) {
    const result = await http.get(`/plants/${queryStringParameters?.plantId}/live-videos`, queryStringParameters);
    this.SET_LIVE_VIDEOS({ LiveVideos: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fetchLiveVideo(id: number, plantId: string) {
    const plant = await http.get(`/plants/${plantId}/live-videos/${id}`);
    this.SET_LIVE_VIDEO(plant);
  }

  @Action({ rawError: true })
  public async createliveVideos(body: LiveVideoPostRequestParameters[]) {
    const plantId = body[0].PlantId;
    const result = await http.post(`/plants/${plantId}/live-videos`, body);
    this.SET_LIVE_VIDEO(result);
  }

  @Action({ rawError: true })
  public async updateLiveVideo(params: { 
      plantId: string;
      body: LiveVideoPostRequestParameters; 
    }) 
    {
      const result = await http.put(`/plants/${params.plantId}/live-videos/${params.body.Id}`, params.body);
      this.SET_LIVE_VIDEO(result);
    }

  @Action({ rawError: true })
  public async deleteLiveVideo(params: LiveVideoDeleteRequestParameters) {
    await http.delete(`/plants/${params.PlantId}/live-videos/${params.Id}`);
  }

  // mutation
  @Mutation
  private SET_LIVE_VIDEOS(payload: { LiveVideos: LiveVideo[]; Pagination: IPagination }) {
    this.liveVideos = payload.LiveVideos;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_LIVE_VIDEO(liveVideo: LiveVideo) {
    this.liveVideo = liveVideo;
  }

  get GET_LIVE_VIDEOS() {
    return this.liveVideos;
  }

  get GET_PAGINATION() {
    return this.pagination;
  }

  get GET_LIVE_VIDEO() {
    return this.liveVideo;
  }
}

export default getModule(LiveVideos);
  