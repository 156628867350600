import { SensorLogs as sensorLog } from "./../../types/sensorLog";
import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { http } from "@/http/http";

@Module({ dynamic: true, store, name: "sensorLogs", namespaced: true })
class SensorLogs extends VuexModule {
  // state
  private sensorLogs: sensorLog[] = [];

  // action
  @Action({ rawError: true })
  // 引数をオブジェクトではなく、引数2つに修正する。しかし、引数2つ目はundefinedになってしまう為、調査
  public async fetchSensorLogs(params: any, isCurrent = false) {
    const response: sensorLog[] = await http.get(
      `/plants/${params.plantId}/sensor-logs`,
      params.queryParams
    );
    if(isCurrent) {
      this.PAGE_CURRENT_SENSOR_LOG_DATA(response);
    }
    else {
      this.SET_SENSOR_LOGS(response);
    }
    
    return response;
  }
  // mutations
  @Mutation
  private SET_SENSOR_LOGS(response: sensorLog[]): void {
    this.sensorLogs = response;
  }

  // mutation
  @Mutation
  private PAGE_CURRENT_SENSOR_LOG_DATA(sensorLogs: sensorLog[]) {
    this.sensorLogs = [...this.sensorLogs, ...sensorLogs];
  }

  // getters
  get GET_SENSOR_LOGS(): sensorLog[] {
    return this.sensorLogs;
  }
}

export default getModule(SensorLogs);
