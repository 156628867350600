/**
 * Options for the selectBox for the priority of the alert_log_ports table.
 */
export const priorityOptions = [
  {
    value: 'none',
    label: 'なし',
  },
  {
    value: 'high',
    label: '高',
  },
  {
    value: 'normal',
    label: '中',
  },
  {
    value: 'low',
    label: '低',
  },
];