import { CompanyType } from "@/types/company";

/**
 * Non-owner options.
 */
export const exceptOwnerCompanyTypeOptions = [
  {
    value: `${CompanyType.ADMIN},${CompanyType.INSTALLATION},${CompanyType.MONITORING},${CompanyType.SALES}`,
    label: "全て",
  },
  {
    value: CompanyType.ADMIN,
    label: "運営会社",
  },
  {
    value: CompanyType.INSTALLATION,
    label: "設置⼯事事業者",
  },
  {
    value: CompanyType.MONITORING,
    label: "監視会社",
  },
  {
    value: CompanyType.SALES,
    label: "営業代行会社",
  },
];
