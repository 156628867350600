import {
  Module,
  getModule,
  VuexModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { http } from '@/http/http';
import { CreateUserRequestBody, User, UsersRequestParameters } from '@/types/user';
import { IPagination } from '@/types/pagination';
import Amplify, { Auth } from "aws-amplify";
import awsExports from "@/aws-exports";

Amplify.configure(awsExports);

@Module({ dynamic: true, store, name: 'users', namespaced: true })
class Users extends VuexModule {
  // state
  private users: User[] = [];
  private singleUsers: any = {};
  private pagination: IPagination = {
    Count: 0,
    Page: 1,
  };
  private currentUser: any = {};

  private normalUserRoles = {
    '会社管理者(GroupAdmin)': "group_admin",
    "会社社員(GroupUser)": "group_user",
  }
  private errorMessage = {
    field: "" as any,
    msg: ""
  };

  // action
  @Action({ rawError: true })
  public async fetchUsers(params: UsersRequestParameters) {
    const result = await http.get('/users', params);
    this.SET_USERS({ Users: result.Data, Pagination: { Count: result.Count, Page: result.CurrentPage } });
  }

  @Action({ rawError: true })
  public async fetchUser(id: string) {
    const user = await http.get(`/users/${id}`);
    this.SET_USER(user);
  }

  @Action({ rawError: true })
  public async createUser(payload: { body: CreateUserRequestBody }) {
    try {
      await http.post('/users', payload.body);  
      this.SET_ERROR_MESSAGE({field: "", msg: ""});
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
      this.SET_ERROR_MESSAGE(errorMessage);
    }
    
  }

  @Action({ rawError: true })
  public async updateUser(payload: {
    id: string;
    body: Partial<CreateUserRequestBody>;
  }) {
    try {
      await http.put(`/users/${payload.id}`, payload.body);
      this.SET_ERROR_MESSAGE({field: "", msg: ""});
    } catch (error: any) {
      const errorMessage = await this.ErrorMessageHandling(error.response.data.Error)
      this.SET_ERROR_MESSAGE(errorMessage);
    }
    
  }

  @Action({})
  public async getCurrentUser() {
    const user = await Auth.currentUserInfo();
    const userInfo = await http.get(`/users/${user.username}`);
    this.SET_CURRENT_USER(userInfo);
  }

  @Action({ rawError: true })
  public async deleteUser(id: string) {
    await http.delete(`/users/${id}`);
  }
  // mutations
  @Mutation
  private SET_USERS(payload: {
    Pagination: IPagination;
    Users: User[];
  }): void {
    this.users = payload.Users;
    this.pagination = payload.Pagination;
  }

  @Mutation
  private SET_USER(user: User): void {
    this.singleUsers[user.Id] = user;
  }

  @Mutation
  private SET_CURRENT_USER(user: User): void {
    this.currentUser = user;
  }

  @Mutation
  private SET_ERROR_MESSAGE(message: any): void {
    this.errorMessage = message;
  }

  @Action({})
  public async ErrorMessageHandling(error:string) {
    let message = {
      field: "",
      msg: "エラーが発生されました"
    }
    
    switch (error) {
      case "duplicate key value violates unique constraint \"users_email_address_key\"":
        message  = {
          field: "emailAddress",
          msg: "メールアドレスは既に使用されています。別のメールアドレスで登録してください。"
        };
        break;
      case "An account with the given email already exists.":
        message  = {
          field: "emailAddress",
          msg: "メールアドレスは既に使用されています。別のメールアドレスで登録してください。"
        };
        break;
        case "duplicate key value violates unique constraint \"tbl_users_email_unique_idx\"": 
          message  = {
            field: "emailAddress",
            msg: "メールアドレスは既に使用されています。別のメールアドレスで登録してください。"
          };
          break;
      // Add more error message here
      default:
        break;
    }
    return message;
 }

  // getters
  get GET_USERS(): User[] {
    return this.users;
  }

  get GET_USER_LOOKUP(): any {
    return this.singleUsers;
  }

  get GET_PAGINATION(): IPagination {
    return this.pagination;
  }

  get GET_NORMAL_USER_ROLE() {
    return this.normalUserRoles;
  }

  get GET_CURRENT_USER() {
    return this.currentUser;
  }

  get GET_ERROR_MESSAGE() {
    return this.errorMessage;
  }
}

export default getModule(Users);
