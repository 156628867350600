import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ForceChangePassword from "@/views/auth/ChangePassword.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import Users from "@/views/users/UserList.vue";
import Login from '@/views/auth/Login.vue';
import UserCreate from '@/views/users/UserCreate.vue';
import UserEdit from '@/views/users/UserEdit.vue';
import UserDetail from '@/views/users/UserDetail.vue';
import Companies from '@/views/companies/CompanyList.vue';
import Alerts from '@/views/alerts/AlertList.vue';
import AlertDetail from '@/views/alerts/AlertDetail.vue'
import CompanyCreate from '@/views/companies/CompanyCreate.vue';
import CompanyEdit from '@/views/companies/CompanyEdit.vue';
import CompanyDetail from '@/views/companies/CompanyDetail.vue';
import Plants from '@/views/plants/PlantList.vue';
import PlantCreate from '@/views/plants/PlantCreate.vue';
import PlantDetail from '@/views/plants/PlantDetail.vue';
import PlantEdit from '@/views/plants/PlantEdit.vue';
import PlantTimeline from '@/views/plants/PlantTimeline.vue';
import MaintenanceSchedule from '@/views/maintenance-schedule/MaintenanceScheduleList.vue';
import MaintenanceScheduleCreate from '@/views/maintenance-schedule/MaintenanceScheduleCreate.vue';
import MaintenanceScheduleEdit from '@/views/maintenance-schedule/MaintenanceScheduleEdit.vue';
import accessControlsModule from '@/store/modules/accessControls';
import OilPriceLogPlantsList from '@/views/oil-price-logs/OilPriceLogPlantsList.vue';
import OilPriceLogList from '@/views/oil-price-logs/OilPriceLogList.vue';
import OilPriceLogCreate from '@/views/oil-price-logs/OilPriceLogCreate.vue';
import OilPriceLogEdit from '@/views/oil-price-logs/OilPriceLogEdit.vue';
import GeneratorDetail from "@/views/generators/GeneratorDetail.vue";
import BusinessMasterList from '@/views/business-masters/BusinessMasterList.vue';
import BusinessMasterCreate from '@/views/business-masters/BusinessMasterCreate.vue';
import BusinessMasterEdit from '@/views/business-masters/BusinessMasterEdit.vue';
import OwnerUserList from '@/views/owners/OwnerList.vue';
import OwnerUserDetail from '@/views/owners/OwnerDetail.vue';
import OwnerUserCreate from '@/views/owners/OwnerCreate.vue';
import OwnerUserEdit from '@/views/owners/OwnerEdit.vue';
import SensorTypeList from '@/views/sensor-types/SensorTypeList.vue';
import SensorTypeCreate from '@/views/sensor-types/SensorTypeCreate.vue';
import SensorTypeEdit from '@/views/sensor-types/SensorTypeEdit.vue';
import PlantsWithSalesPricesList from '@/views/salesPrices/SalesPricePlantsList.vue';
import SalesPricesList from '@/views/salesPrices/SalesPriceList.vue';
import BusinessMasterDetail from '@/views/business-masters/BusinessMasterDetail.vue';
import MyPage from '@/views/myPage/UserDetail.vue';
import MyPageEdit from '@/views/myPage/UserEdit.vue';
import MyPageCompany from '@/views/myPage/CompanyDetail.vue';
import MyPageCompanyEdit from '@/views/myPage/CompanyEdit.vue';
import MonitoringList from '@/views/monitoring/MonitoringList.vue';

import { AuthFilter } from '@/router/auth';
import getCrudPermission from '@/store/helpers/getPermission';
import { Auth } from 'aws-amplify';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
     try {
      if (from.name === "forcechangepassword" ) {
        next();
        return;
      }

       // if already logged in, can not open login screen
      await Auth.currentAuthenticatedUser();
      next(from);  
      return;
     } catch (error) {
      next();
      return;
     }
    },
    meta: {
      title: 'ログイン',
      isPublic: true,
    },
  },
  {
    path: '/force-change-password',
    name: 'forcechangepassword',
    component: ForceChangePassword,
    beforeEnter: async (to, from, next) => {
      // Check if not first time login, can not access this page
      if(to.params["challengeName"] === "NEW_PASSWORD_REQUIRED") {
        next();
        return;
      }
      next(from);
      return;
    },
    meta: {
      title: 'パスワード変更',
      isPublic: true,
    }

  },
  {
    path: '/forgot-password',
    name: 'forgotpassworx',
    component: ForgotPassword,
    meta: {
      title: 'パスワードを忘れ',
      isPublic: true,
    }

  },
  {
    path: '/my-page',
    name: 'mypage',
    component: MyPage,
    meta: {
      title: 'マイページ',
    }
  },
  {
    path: '/my-page/:id/edit',
    name: 'mypageEdit',
    component: MyPageEdit,
    meta: {
      title: 'マイページ更新',
    }
  },
  {
    path: '/my-page/company/:id',
    name: 'mypageCompany',
    component: MyPageCompany,
    meta: {
      title: '所属会社',
    }
  },
  {
    path: '/my-page/company/:id/edit',
    name: 'mypageCompanyEdit',
    component: MyPageCompanyEdit,
    meta: {
      title: '所属会社更新',
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'ユーザ一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "user");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/users/register',
    name: 'usersregister',
    component: UserCreate,
    meta: {
      title: 'ユーザー登録',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "user");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/users/:id/edit',
    name: 'useredit',
    component: UserEdit,
    meta: {
      title: 'ユーザー修正',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "user");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/users/:id',
    name: 'userdetail',
    component: UserDetail,
    meta: {
      title: 'ユーザー詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "user");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },

  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      title: '会社一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "company");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: Alerts,
    meta: {
      title: 'アラート一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "alert");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/alerts/:id',
    name: 'alertDetail',
    component: AlertDetail,
    meta: {
      title: 'アラート詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "alert");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/companies/create',
    name: 'companiescreate',
    component: CompanyCreate,
    meta: {
      title: '会社新規登録',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "company");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/companies/:id/edit',
    name: 'companiesedit',
    component: CompanyEdit,
    meta: {
      title: '会社更新',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "company");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },

  {
    path: '/companies/:id',
    name: 'companiesdetail',
    component: CompanyDetail,
    meta: {
      title: '会社詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "company");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants',
    name: 'plants',
    component: Plants,
    meta: {
      title: '発電所一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "plant");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants/create',
    name: 'plantscreate',
    component: PlantCreate,
    meta: {
      title: '発電所新規登録',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "plant");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants/:id',
    name: 'plantsdetail',
    component: PlantDetail,
    meta: {
      title: '発電所詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "plant");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants/:plantId/generators/:psnImsi/:generatorNumber',
    name: 'generatordetail',
    component: GeneratorDetail,
    meta: {
      title: '発電機詳細情報',
    },
  },
  {
    path: '/plants/:id/edit',
    name: 'plantsedit',
    component: PlantEdit,
    meta: {
      title: '発電所修正',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "plant");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },

  {
    path: '/maintenance-schedules',
    name: 'maintenanceSchedule',
    component: MaintenanceSchedule,
    meta: {
      title: 'メンテナンススケジュール一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "maintenance");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/maintenance-schedules/create',
    name: 'maintenanceSchedulesCreate',
    component: MaintenanceScheduleCreate,
    meta: {
      title: 'メンテナンススケジュール新規登録',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "maintenance");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/maintenance-schedules/:id/edit',
    name: 'maintenanceSchedulesEdit',
    component: MaintenanceScheduleEdit,
    meta: {
      title: 'メンテナンススケジュール更新',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "maintenance");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/oil-price-log-plants',
    name: 'oilPriceLogPlants',
    component: OilPriceLogPlantsList,
    meta: {
      title: '発電所燃料管理一覧',
    }, 
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "oilPriceLog");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants/:id/oil-price-logs',
    name: 'oilPriceLogs',
    component: OilPriceLogList, 
    meta: {
      title: '燃料管理一覧',
    }
  },
  {
    path: '/plants/:id/oil-price-logs/create',
    name: 'oilPriceLogCreate',
    component: OilPriceLogCreate,
    meta: {
      title: '燃料管理登録',
    }
  },
  {
    path: '/plants/:id/oil-price-logs/:oilPriceLogId/edit',
    name: 'oilPriceLogEdit',
    component: OilPriceLogEdit,
    meta: {
      title: '燃料管理更新',
    }
  },

  {
    path: '/owners',
    name: 'owners',
    component: BusinessMasterList,
    meta: {
      title: '事業者マスター',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "owner");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owners/create',
    name: 'ownerCreate',
    component: BusinessMasterCreate,
    meta: {
      title: '事業者マスター新規登録',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "owner");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owners/:id/edit',
    name: 'ownerEdit',
    component: BusinessMasterEdit,
    meta: {
      title: '事業者マスター更新',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "owner");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owners/:id',
    name: 'ownerDetail',
    component: BusinessMasterDetail,
    meta: {
      title: '事業者マスター詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "owner");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },

  {
    path: '/owner-users/',
    name: 'ownerUsers',
    component: OwnerUserList,
    meta: {
      title: '事業者ユーザー一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "ownerUser");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owner-users/:id',
    name: 'ownerUserDetail',
    component: OwnerUserDetail,
    meta: {
      title: '事業者ユーザー詳細',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "ownerUser");
      if(canViewDetail) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owner-users/create',
    name: 'ownerUserCreate',
    component: OwnerUserCreate,
    meta: {
      title: '事業者ユーザー作成',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "ownerUser");
      if(canCreate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/owner-users/:id/edit',
    name: 'ownerUserEdit',
    component: OwnerUserEdit,
    meta: {
      title: '事業者ユーザー更新',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "ownerUser");
      if(canUpdate) {
        next();
        return;
      }
      next(from);
      return;
    }
  },

  
  {
    path: '/sensor-types',
    name: 'sensorTypes',
    component: SensorTypeList,
    meta: {
      title: '信号種別一覧',
    }
  },
  {
    path: '/sensor-types/create',
    name: 'SensorTypeCreate',
    component: SensorTypeCreate,
    meta: {
      title: '信号種別登録',
    }
  },
  {
    path: '/sensor-types/:id/edit',
    name: 'SensorTypeEdit',
    component: SensorTypeEdit,
    meta: {
      title: '信号種別更新',
    }
  },

  {
    path: '/plants-with-sales-prices',
    name: 'plantsWithSalesPricesList',
    component: PlantsWithSalesPricesList, 
    meta: {
      title: 'プラント(売電価格)一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "salesPriceLog");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/plants/:id/sales-prices',
    name: 'salesPricesList',
    component: SalesPricesList, 
    meta: {
      title: '売電価格一覧',
    },
    beforeEnter: async (to,from,next)=>{
      const acl = accessControlsModule.GET_ACCESS_CONTROL;
      const [canCreate, canViewDetail, canViewAll, canUpdate, canDelete] =
      getCrudPermission(acl, "salesPriceLog");
      if(canViewAll) {
        next();
        return;
      }
      next(from);
      return;
    }
  },
  {
    path: '/monitoring',
    name: 'monitoringList',
    component: MonitoringList, 
    meta: {
      title: 'モニタリング一覧',
    },
    // TODO: アクセスコントロールを追加する
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach(AuthFilter);